import React, { useState, useEffect, useMemo } from 'react';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import axiosInstance from '../utils/axiosInstance';
import Header from '../components/Header';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../styles/calendar.css';
import RecurringJobModal from '../components/RecurringJobModal';
import { Modal } from 'react-bootstrap';
import { Select } from 'antd';
import { toast } from 'react-hot-toast';
import RouteDetailsModal from '../components/RouteDetailsModal';
import JobDetailsModal from '../components/JobDetailsModal';

const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(BigCalendar);

const Calendar = () => {
  const [rawEvents, setRawEvents] = useState([]);
  const [unscheduledJobs, setUnscheduledJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [selectedRouteJobs, setSelectedRouteJobs] = useState([]);
  const [showRouteDetails, setShowRouteDetails] = useState(false);
  const [showRouteModal, setShowRouteModal] = useState(false);
  const [modalState, setModalState] = useState({ 
    isOpen: false, 
    jobData: null, 
    dropDate: null, 
    existingRoute: null 
  });
  const [jobData, setJobData] = useState(null);
  const [dropDate, setDropDate] = useState(null);
  const [existingRoute, setExistingRoute] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentView, setCurrentView] = useState('month');
  const [selectedJob, setSelectedJob] = useState(null);
  const [showJobDetails, setShowJobDetails] = useState(false);

  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    try {
      setLoading(true);
      const start = moment().startOf('month').toISOString();
      const end = moment().endOf('month').toISOString();

      const [eventsResponse, jobsResponse] = await Promise.all([
        axiosInstance.get(`/calendar/events?start=${start}&end=${end}`),
        axiosInstance.get('/calendar/unscheduled-jobs')
      ]);

      console.log('Raw events from API:', eventsResponse.data);
      console.log('Sample event structure:', eventsResponse.data[0]);
      if (eventsResponse.data[0]?.job) {
        console.log('Sample job data:', eventsResponse.data[0].job);
        console.log('Sample customer data:', eventsResponse.data[0].job.customer);
      }

      setRawEvents(eventsResponse.data);
      setUnscheduledJobs(jobsResponse.data);
    } catch (err) {
      setError('Failed to load calendar data');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const formatEvent = (event, allEvents, view) => {
    console.log('Formatting event:', event, 'View:', view);
    
    // Helper function to format customer name
    const formatCustomerName = (customer) => {
      console.log('Raw customer data:', customer);
      if (!customer || typeof customer === 'string') {
        console.log('Invalid customer data:', customer);
        return 'No Customer';
      }
      if (customer.name) {
        console.log('Using customer.name:', customer.name);
        return customer.name;
      }
      if (customer.firstName || customer.lastName) {
        const fullName = `${customer.firstName || ''} ${customer.lastName || ''}`.trim();
        console.log('Using firstName/lastName:', fullName);
        return fullName;
      }
      console.log('Falling back to email:', customer.email);
      return customer.email || 'No Customer';
    };

    // For week and day views - show individual jobs
    if (view === 'week' || view === 'day') {
      const startTime = new Date(event.start);
      
      // Get all events for the same route on the same day
      const routeEvents = event.route ? allEvents.filter(e => 
        e.route?._id === event.route._id && 
        new Date(e.start).toDateString() === startTime.toDateString()
      ).sort((a, b) => new Date(a.start) - new Date(b.start)) : [];

      console.log('Route events for', event._id, ':', {
        routeId: event.route?._id,
        date: startTime.toDateString(),
        totalEvents: routeEvents.length,
        eventIds: routeEvents.map(e => e._id)
      });

      // Find position of current event in route
      const eventIndex = routeEvents.findIndex(e => e._id === event._id);
      
      // Set base time to 8 AM
      startTime.setHours(8, 0, 0, 0);
      
      // Stack events by adding previous durations
      if (routeEvents.length > 0 && eventIndex > 0) {
        let totalMinutes = 0;
        for (let i = 0; i < eventIndex; i++) {
          const prevEvent = routeEvents[i];
          // Each job takes 60 minutes by default
          const durationMinutes = 60;
          totalMinutes += durationMinutes;
          console.log('Adding duration for previous event:', {
            prevEventId: prevEvent._id,
            duration: durationMinutes + ' minutes',
            runningTotal: totalMinutes + ' minutes'
          });
        }
        // Add the accumulated minutes
        startTime.setMinutes(startTime.getMinutes() + totalMinutes);
      }
      
      // Set end time based on duration (60 minutes per job)
      const durationMinutes = 60;
      const endTime = new Date(startTime);
      endTime.setMinutes(endTime.getMinutes() + durationMinutes);

      console.log('Final event timing:', { 
        id: event._id,
        routeId: event.route?._id,
        startTime: startTime.toLocaleTimeString(),
        endTime: endTime.toLocaleTimeString(),
        duration: durationMinutes + ' minutes',
        eventIndex,
        totalEvents: routeEvents.length
      });

      // Get customer name from the job data
      const customerName = event.job?.customer ? formatCustomerName(event.job.customer) : 'No Customer';
      const serviceName = event.job?.service || 'No Service';

      return {
        id: `job-${event._id}`,
        title: `${customerName} - ${serviceName}`,
        start: startTime,
        end: endTime,
        resource: {
          job: event.job,
          route: event.route,
          isRecurring: event.isRecurring,
          type: 'job'
        },
        allDay: false,
        className: `calendar-event ${event.isRecurring ? 'recurring-event' : ''} ${event.route ? 'route-job' : ''} ${event.selected ? 'selected-event' : ''}`,
        backgroundColor: event.route ? '#3182ce' : '#4ade80'
      };
    }

    // For month view - group by route
    if (event.route) {
      const routeEvents = allEvents.filter(e => 
        e.route && 
        e.route._id === event.route._id &&
        new Date(e.start).toDateString() === new Date(event.start).toDateString()
      );
      
      if (event === routeEvents[0] || routeEvents.length === 0) {
        const startTime = new Date(event.start);
        startTime.setHours(8, 0, 0, 0);
        const endTime = new Date(startTime);
        endTime.setHours(17, 0, 0, 0); // Set end time to 5 PM

        return {
          id: `route-${event.route._id}-${new Date(event.start).toDateString()}`,
          title: `${event.route.name || 'Route'} (${routeEvents.length} jobs)`,
          start: startTime,
          end: endTime,
          resource: {
            route: event.route,
            jobs: routeEvents.map(e => e.job),
            type: 'route'
          },
          allDay: false,
          className: 'route-event bg-gradient-to-br from-purple-500 to-purple-600',
          backgroundColor: routeEvents.length === 0 ? '#6B7280' : '#8B5CF6'
        };
      }
      return null;
    }

    // For individual jobs not in a route
    const startTime = new Date(event.start);
    startTime.setHours(8, 0, 0, 0);
    const endTime = new Date(startTime);
    const duration = event.job?.estimatedDuration || 3600000;
    endTime.setTime(startTime.getTime() + duration);

    // Get customer name from the job data
    const customerName = event.job?.customer ? formatCustomerName(event.job.customer) : 'No Customer';
    const serviceName = event.job?.service || 'No Service';

    return {
      id: `job-${event._id}`,
      title: `${customerName} - ${serviceName}`,
      start: startTime,
      end: endTime,
      resource: {
        job: event.job,
        isRecurring: event.isRecurring,
        type: 'job'
      },
      allDay: false,
      className: 'calendar-event bg-gradient-to-br from-purple-500 to-purple-600',
      backgroundColor: event.isRecurring ? '#8B5CF6' : '#8B5CF6'
    };
  };

  const formattedEvents = useMemo(() => {
    console.log('Raw events:', rawEvents);
    const formatted = rawEvents
      .map(event => formatEvent(event, rawEvents, currentView))
      .filter(Boolean);
    console.log('Formatted events:', formatted);
    return formatted;
  }, [rawEvents, currentView]);

  const handleRecurringJobDrop = async (jobData, existingRoute, start) => {
    try {
      // Validate the date first
      const startDate = new Date(start);
      if (isNaN(startDate.getTime())) {
        toast.error('Invalid drop date');
        return;
      }

      // Calculate end date using estimated duration or default to 1 hour
      const duration = jobData.estimatedDuration || 3600000; // 1 hour in milliseconds
      const endDate = new Date(startDate.getTime() + duration);

      // Normalize the frequency
      const normalizedFrequency = jobData.recurrencePattern.toLowerCase().replace('-', '');
      
      console.log('Creating calendar event with dates:', {
        start: startDate.toISOString(),
        end: endDate.toISOString()
      });

      const response = await axiosInstance.post('/routes/add-job', {
        routeId: existingRoute._id,
        jobId: jobData._id,
        createSeries: true,
        frequency: normalizedFrequency,
        futureOnly: true,
        start: startDate.toISOString(),
        end: endDate.toISOString()
      });

      await fetchInitialData();
      toast.success('Job scheduled successfully');
      
    } catch (error) {
      console.error('Error in handleRecurringJobDrop:', error);
      toast.error(error.response?.data?.message || 'Failed to schedule job');
    }
  };

  const handleJobDrop = async (jobData, existingRoute, start) => {
    try {
      // Validate the date first
      const startDate = new Date(start);
      if (isNaN(startDate.getTime())) {
        toast.error('Invalid drop date');
        return;
      }

      // If job is recurring and dropped on existing route
      if (jobData.isRecurring && existingRoute) {
        await handleRecurringJobDrop(jobData, existingRoute, start);
        return;
      }

      // For non-recurring jobs or new routes
      const response = await axiosInstance.post('/calendar/events', {
        jobId: jobData._id,
        date: startDate.toISOString(),
        routeId: existingRoute?._id,
        createSeries: false
      });

      await fetchInitialData();
      toast.success('Job scheduled successfully');

    } catch (error) {
      console.error('Error in handleJobDrop:', error);
      toast.error(error.response?.data?.message || 'Failed to schedule job');
    }
  };

  const handleDragStart = async (e, job) => {
    console.log('Drag started:', { job });
    try {
      // Store the dragged job ID in the backend
      await axiosInstance.post('/calendar/drag-start', { jobId: job._id });
      
      const eventData = {
        title: job.service,
        resource: { job }
      };
      console.log('Setting drag data:', eventData);
      e.dataTransfer.setData('event', JSON.stringify(eventData));
      e.dataTransfer.setData('text/plain', ''); // Required for Firefox
    } catch (err) {
      console.error('Error in handleDragStart:', err);
    }
  };

  const handleDrop = async (event) => {
    console.log('Drop event received:', event);
    
    try {
      // Get the dragged job
      const draggedJobResponse = await axiosInstance.get('/calendar/last-dragged');
      console.log('Dragged job response:', draggedJobResponse.data);
      const job = draggedJobResponse.data.job;
      console.log('Processing job:', {
        id: job._id,
        service: job.service,
        isRecurring: job.isRecurring,
        recurrencePattern: job.recurrencePattern
      });
      setJobData(job);

      // Set drop date
      const dropDate = event.start;
      console.log('Drop date:', dropDate);
      setDropDate(dropDate);

      // Check for existing routes
      const date = new Date(dropDate);
      const formattedDate = date.toISOString().split('T')[0];
      console.log('Checking for routes on:', formattedDate);
      
      // Get routes from events for this date
      const eventsResponse = await axiosInstance.get(`/calendar/events?start=${formattedDate}T00:00:00.000Z&end=${formattedDate}T23:59:59.999Z`);
      console.log('Events response:', eventsResponse.data);

      // Extract unique routes from events
      const existingRoutes = eventsResponse.data
        .filter(event => event.route)
        .reduce((routes, event) => {
          const routeExists = routes.some(r => r._id === event.route._id);
          if (!routeExists) {
            routes.push(event.route);
          }
          return routes;
        }, []);

      console.log('Available routes:', existingRoutes);
      console.log('Number of routes found:', existingRoutes.length);
      console.log('Route details:', existingRoutes.map(route => ({
        id: route._id,
        name: route.name,
        jobCount: route.jobs?.length || 0
      })));

      // Set modal state based on job type and available routes
      setModalState({
        isOpen: true,
        jobData: job,
        dropDate: dropDate,
        routes: existingRoutes,
        existingRoute: null, // Don't set a default route
        requireRouteSelection: existingRoutes.length > 0
      });

    } catch (error) {
      console.error('Error handling drop:', error);
      toast.error('Failed to process drop');
    }
  };

  const handleModalSelect = async (choice, routeId, routeName) => {
    try {
      if (!jobData || !dropDate) {
        console.error('Missing required data:', { jobData, dropDate });
        toast.error('Missing job data or drop date');
        return;
      }

      console.log('Modal selection:', {
        choice,
        routeId,
        routeName,
        jobData: {
          id: jobData._id,
          isRecurring: jobData.isRecurring,
          recurrencePattern: jobData.recurrencePattern,
          service: jobData.service
        },
        dropDate: dropDate.toISOString()
      });

      const startDate = new Date(dropDate);
      const duration = jobData.estimatedDuration || 3600000;
      const endDate = new Date(startDate.getTime() + duration);

      if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
        console.error('Invalid dates:', { startDate, endDate });
        toast.error('Invalid drop date');
        return;
      }

      let response;
      const eventData = {
        jobId: jobData._id,
        date: startDate.toISOString(),
        createSeries: false
      };

      // Always include route name if provided
      if (routeName?.trim()) {
        console.log('Setting route name:', routeName.trim());
        eventData.routeName = routeName.trim();
      }
      
      // Handle one-time jobs
      if (!jobData.isRecurring) {
        console.log('Handling one-time job selection:', choice);
        if (choice === 'selectRoute' && routeId) {
          console.log('Adding one-time job to existing route:', routeId);
          eventData.routeId = routeId;
          response = await axiosInstance.post('/calendar/events', eventData);
        } else if (choice === 'newRoute') {
          console.log('Creating new route for one-time job');
          response = await axiosInstance.post('/calendar/events', eventData);
        }
      } else {
        // Handle recurring job options
        switch (choice) {
          case 'single':
            console.log('Creating single event for recurring job');
            if (routeId) eventData.routeId = routeId;
            response = await axiosInstance.post('/calendar/events', eventData);
            break;

          case 'newSeries':
            console.log('Creating new series for recurring job');
            eventData.createSeries = true;
            response = await axiosInstance.post('/calendar/events', eventData);
            break;

          case 'attachFuture':
          case 'attachAll':
            if (!routeId) {
              console.error('No route ID provided for attaching to series');
              toast.error('No route selected');
              return;
            }
            
            const routeData = {
              routeId,
              jobId: jobData._id,
              createSeries: true,
              frequency: jobData.recurrencePattern?.toLowerCase().replace('-', '') || 'weekly',
              futureOnly: choice === 'attachFuture',
              start: startDate.toISOString(),
              end: endDate.toISOString()
            };

            // Add route name if provided
            if (routeName?.trim()) {
              console.log('Setting route name for series:', routeName.trim());
              routeData.routeName = routeName.trim();
            }

            response = await axiosInstance.post('/routes/add-job', routeData);
            break;

          default:
            console.error('Unknown modal choice:', choice);
            return;
        }
      }

      console.log('API response:', response?.data);
      await fetchInitialData();
      toast.success('Job scheduled successfully');
      setModalState({ isOpen: false });
      
    } catch (error) {
      console.error('Error in handleModalSelect:', error);
      toast.error(error.response?.data?.message || 'Failed to schedule job');
    }
  };

  const handleRouteSelect = (routeId) => {
    setModalState(prev => ({
      ...prev,
      selectedRouteId: routeId
    }));
  };

  const onDropFromOutside = (dropEvent) => {
    console.log('onDropFromOutside called:', dropEvent);
    if (!dropEvent || !dropEvent.start) {
      console.error('Invalid drop event:', dropEvent);
      return;
    }
    handleDrop(dropEvent);
  };

  const handleEventClick = async (event) => {
    try {
      console.log('Event clicked:', event);
      
      // Check if this is a job or route event
      if (event.resource.type === 'job') {
        console.log('Job clicked:', event.resource.job);
        
        // Fetch complete job details including customer info
        const jobResponse = await axiosInstance.get(`/jobs/${event.resource.job._id}`);
        console.log('Fetched job details:', jobResponse.data);
        
        setSelectedJob(jobResponse.data);
        setShowJobDetails(true);
        return;
      }
      
      // Handle route clicks
      if (event.resource.type === 'route') {
        const routeId = event.resource.route._id;
        console.log('Fetching route:', routeId);
        
        const routeResponse = await axiosInstance.get(`/routes/${routeId}`);
        console.log('Route response:', routeResponse.data);
        
        setSelectedRoute(routeResponse.data);
        setSelectedRouteJobs(routeResponse.data.jobs);
        setShowRouteDetails(true);
      }
    } catch (error) {
      console.error('Error fetching details:', error);
      toast.error('Failed to load details');
    }
  };

  const handleNonRecurringDrop = async (job, dropDate, existingRoute) => {
    try {
      if (existingRoute) {
        // Add to existing route
        await axiosInstance.post('/routes/add-job', {
          routeId: existingRoute._id,
          jobId: job._id,
          date: dropDate,
          createSeries: false
        });
      } else {
        // Create new route
        await axiosInstance.post('/routes', {
          date: dropDate,
          jobId: job._id,
          createSeries: false
        });
      }
      
      // Refresh calendar data
      await fetchInitialData();
    } catch (err) {
      console.error('Error handling non-recurring drop:', err);
      setError('Failed to schedule job');
    }
  };

  const checkForExistingRoute = async (date) => {
    try {
      const formattedDate = moment(date).format('YYYY-MM-DD');
      const response = await axiosInstance.get(`/routes/by-date/${formattedDate}`);
      return response.data.route || null;
    } catch (err) {
      console.error('Error checking for existing route:', err);
      return null;
    }
  };

  if (loading) return <div>Loading calendar...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="min-h-screen bg-gray-900">
      <Header />
      <div className="flex h-[calc(100vh-64px)]">
        {/* Job Pool Sidebar */}
        <div className="w-1/4 p-4 bg-gray-800 overflow-y-auto border-r border-gray-700">
          <h2 className="text-xl font-bold mb-4 text-white">Unscheduled Jobs</h2>
          <div className="space-y-2">
            {unscheduledJobs.map(job => (
              <div
                key={job._id}
                className="p-4 bg-gradient-to-br from-gray-700 to-gray-800 rounded-lg shadow-lg cursor-move border border-gray-600 hover:border-blue-500 transform hover:scale-105 transition-all duration-200"
                draggable
                onDragStart={(e) => handleDragStart(e, job)}
              >
                <div className="font-medium text-white">{job.service}</div>
                <div className="text-sm text-gray-300">
                  {job.customer?.name || 'No customer assigned'}
                </div>
                {job.isRecurring && (
                  <span className="text-xs bg-green-900 text-green-100 px-3 py-1 rounded-full mt-2 inline-block">
                    {job.recurrencePattern}
                  </span>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Calendar */}
        <div className="flex-1 p-4 bg-gray-900">
          <DragAndDropCalendar
            localizer={localizer}
            events={formattedEvents}
            onEventDrop={handleJobDrop}
            onDropFromOutside={onDropFromOutside}
            draggableAccessor={() => true}
            resizable={false}
            style={{ height: 'calc(100vh - 100px)' }}
            onSelectEvent={handleEventClick}
            droppable={true}
            onView={setCurrentView}
            view={currentView}
            min={new Date(2020, 1, 1, 8, 0, 0)} // Start at 8 AM
            max={new Date(2020, 1, 1, 17, 0, 0)} // End at 5 PM
            className="bg-gray-800 rounded-lg shadow-xl border border-gray-700"
          />
          <RecurringJobModal
            isOpen={modalState.isOpen}
            onClose={() => setModalState({ isOpen: false })}
            onSelect={handleModalSelect}
            existingRoute={modalState.existingRoute}
            routes={modalState.routes}
            job={modalState.jobData}
          />
          <RouteDetailsModal
            isOpen={showRouteDetails}
            onClose={() => setShowRouteDetails(false)}
            route={selectedRoute}
            jobs={selectedRouteJobs}
            onUpdate={async () => {
              try {
                await fetchInitialData();
                // Only try to fetch the updated route if we're not deleting it
                if (selectedRoute) {
                  const updatedRoute = await axiosInstance.get(`/routes/${selectedRoute._id}`);
                  if (updatedRoute.data) {
                    setSelectedRoute(updatedRoute.data);
                    setSelectedRouteJobs(updatedRoute.data.jobs);
                  } else {
                    // Route was deleted, close the modal
                    setShowRouteDetails(false);
                    setSelectedRoute(null);
                    setSelectedRouteJobs([]);
                  }
                }
              } catch (error) {
                // If we get a 404, the route was deleted
                if (error.response?.status === 404) {
                  setShowRouteDetails(false);
                  setSelectedRoute(null);
                  setSelectedRouteJobs([]);
                } else {
                  console.error('Error updating route:', error);
                  toast.error('Failed to update route details');
                }
              }
            }}
          />
          <JobDetailsModal
            isOpen={showJobDetails}
            onClose={() => setShowJobDetails(false)}
            job={selectedJob}
            onUpdate={async () => {
              await fetchInitialData();
              setShowJobDetails(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Calendar;