import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const ProtectedRoute = ({ requireSubscription = false }) => {
  const { user, loading } = useAuth();

  // Show loading state or return null while checking authentication
  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-900">
        <div className="text-center text-white">
          <h2 className="text-xl font-semibold mb-2">Loading...</h2>
          <p>Please wait while we verify your session...</p>
        </div>
      </div>
    );
  }

  if (!user) {
    console.log('[Auth Debug] ProtectedRoute: No user found, redirecting to signin');
    // Not logged in, redirect to login page
    return <Navigate to="/signin" replace />;
  }

  if (requireSubscription && (!user.subscriptionActive || !user.subscriptionTier)) {
    console.log('[Auth Debug] ProtectedRoute: No active subscription, redirecting to pricing');
    // No active subscription when required, redirect to pricing
    return <Navigate to="/pricing" replace state={{ 
      message: 'Please select a subscription plan to access this feature'
    }} />;
  }

  console.log('[Auth Debug] ProtectedRoute: Access granted');
  // Authorized, render children
  return <Outlet />;
};

export default ProtectedRoute;
