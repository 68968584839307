import React, { useState, useEffect } from 'react';
import { formatCurrency, formatDate } from '../utils/formatters';
import { toast } from 'react-hot-toast';
import axiosInstance from '../utils/axiosInstance';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const RouteDetailsModal = ({ isOpen, onClose, route, jobs, onUpdate }) => {
  const [crews, setCrews] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedCrew, setSelectedCrew] = useState('');
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [currentJobIndex, setCurrentJobIndex] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [routeName, setRouteName] = useState('');
  const [loading, setLoading] = useState(false);
  const [showRemoveOptions, setShowRemoveOptions] = useState(false);
  
  const currentJob = jobs[currentJobIndex];
  const totalRevenue = jobs.reduce((sum, job) => sum + (job.price || 0), 0);
  const totalDuration = jobs?.reduce((sum, job) => sum + (job.estimatedDuration || 3600000), 0);
  const formatTotalDuration = (ms) => {
    const hours = Math.floor(ms / 3600000);
    const minutes = Math.round((ms % 3600000) / 60000);
    return `${hours}h ${minutes}m`;
  };

  useEffect(() => {
    if (route) {
      setRouteName(route.name || '');
      setSelectedCrew(route.assignedCrew?._id || route.assignedCrew || '');
      setSelectedEmployee(route.assignedEmployee?._id || route.assignedEmployee || '');
      console.log('Route data loaded:', {
        routeId: route._id,
        assignedCrew: route.assignedCrew,
        assignedEmployee: route.assignedEmployee
      });
    }
  }, [route]);

  useEffect(() => {
    if (isOpen) {
      fetchCrewsAndEmployees();
    }
  }, [isOpen]);

  const fetchCrewsAndEmployees = async () => {
    try {
      const [crewsRes, employeesRes] = await Promise.all([
        axiosInstance.get('/crews'),
        axiosInstance.get('/employees')
      ]);
      setCrews(crewsRes.data);
      setEmployees(employeesRes.data.filter(emp => emp.status === 'Active'));
    } catch (error) {
      console.error('Error fetching crews and employees:', error);
      toast.error('Failed to load crews and employees');
    }
  };

  const handleAssignment = async (type, id) => {
    try {
      setLoading(true);
      console.log(`Assigning ${type} with ID:`, id);
      
      if (type === 'crew') {
        setSelectedEmployee('');
      } else if (type === 'employee') {
        setSelectedCrew('');
      }
      
      const response = await axiosInstance.put(`/routes/${route._id}`, {
        [type === 'crew' ? 'assignedCrew' : 'assignedEmployee']: id,
        [type === 'crew' ? 'assignedEmployee' : 'assignedCrew']: null
      });

      console.log('Assignment response:', response.data);

      if (type === 'crew') {
        setSelectedCrew(id);
      } else if (type === 'employee') {
        setSelectedEmployee(id);
      }

      const updatedRoute = await axiosInstance.get(`/routes/${route._id}`);
      console.log('Updated route data:', updatedRoute.data);

      if (onUpdate) {
        await onUpdate();
      }

      toast.success(`Route assigned to ${type} successfully`);
    } catch (error) {
      console.error('Error assigning route:', error);
      toast.error('Failed to assign route');
      
      if (type === 'crew') {
        setSelectedCrew(route?.assignedCrew?._id || route?.assignedCrew || '');
      } else if (type === 'employee') {
        setSelectedEmployee(route?.assignedEmployee?._id || route?.assignedEmployee || '');
      }
    } finally {
      setLoading(false);
    }
  };

  const nextJob = () => {
    setCurrentJobIndex((prev) => (prev + 1) % jobs.length);
  };

  const prevJob = () => {
    setCurrentJobIndex((prev) => (prev - 1 + jobs.length) % jobs.length);
  };

  const handleSaveName = async () => {
    try {
      setLoading(true);
      await axiosInstance.put(`/routes/${route._id}`, { name: routeName });
      toast.success('Route name updated');
      setIsEditing(false);
      if (onUpdate) onUpdate();
    } catch (error) {
      console.error('Error updating route name:', error);
      toast.error('Failed to update route name');
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveJob = async (jobId, removeFromAll = false) => {
    try {
      setLoading(true);
      console.log('Starting job removal process for job:', jobId);
      
      const jobResponse = await axiosInstance.get(`/jobs/${jobId}`);
      const job = jobResponse.data;
      console.log('Job details:', job);
      
      const eventsResponse = await axiosInstance.get(`/calendar/events`, {
        params: { jobId }
      });
      const allJobEvents = eventsResponse.data;
      console.log(`Found ${allJobEvents.length} events for job:`, allJobEvents);
      
      if (removeFromAll) {
        console.log('Removing job from all routes');
        await axiosInstance.delete(`/calendar/events`, {
          data: { jobId }
        });
        
        await axiosInstance.put(`/jobs/${jobId}`, {
          status: 'Pending',
          route: null,
          scheduledDay: null,
          resetSeries: job.isRecurring
        });
      } else {
        const isOnlyRoute = allJobEvents.every(event => event.route?._id === route._id);
        console.log('Is only route:', isOnlyRoute);
        
        console.log('Deleting calendar events for job and route');
        await axiosInstance.delete(`/calendar/events`, {
          data: {
            jobId,
            routeId: route._id
          }
        });

        if (isOnlyRoute) {
          console.log('Resetting job completely - was only route');
          await axiosInstance.put(`/jobs/${jobId}`, {
            status: 'Pending',
            route: null,
            scheduledDay: null,
            resetSeries: job.isRecurring
          });
        } else {
          console.log('Removing job from current route only');
          await axiosInstance.put(`/jobs/${jobId}`, {
            route: null,
            removeFromRoute: route._id
          });
        }
      }

      await axiosInstance.put(`/routes/${route._id}`, {
        $pull: { jobs: jobId }
      });

      toast.success(removeFromAll ? 'Job removed from all routes' : 'Job removed from route');
      console.log('Job removal completed successfully');
      setShowRemoveOptions(false);
      
      if (onUpdate) {
        onUpdate();
      }
      
      if (currentJobIndex >= jobs.length - 1) {
        setCurrentJobIndex(Math.max(0, jobs.length - 2));
      }
    } catch (error) {
      console.error('Error removing job:', error);
      console.error('Error details:', error.response?.data);
      toast.error(error.response?.data?.message || 'Failed to remove job');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteRoute = async () => {
    try {
      setLoading(true);
      console.log('Starting route deletion process for route:', route._id);
      
      const routeJobs = jobs || [];
      console.log(`Processing ${routeJobs.length} jobs in route`);
      
      for (const job of routeJobs) {
        console.log('Processing job:', job._id);
        
        const eventsResponse = await axiosInstance.get(`/calendar/events`, {
          params: { jobId: job._id }
        });
        const allJobEvents = eventsResponse.data;
        console.log(`Found ${allJobEvents.length} events for job:`, allJobEvents);
        
        const isOnlyRoute = allJobEvents.every(event => event.route?._id === route._id);
        console.log('Is only route:', isOnlyRoute);
        
        if (isOnlyRoute && job.status !== 'Completed' && job.status !== 'Inactive') {
          console.log('Resetting job status');
          await axiosInstance.put(`/jobs/${job._id}`, {
            status: 'Pending',
            route: null,
            scheduledDay: null,
            resetSeries: job.isRecurring
          });
        }
      }
      
      console.log('Deleting all calendar events for route');
      await axiosInstance.delete(`/calendar/events`, {
        data: { routeId: route._id }
      });
      
      console.log('Deleting route');
      await axiosInstance.delete(`/routes/${route._id}`);
      
      toast.success('Route deleted');
      console.log('Route deletion completed successfully');
      onClose();
      if (onUpdate) onUpdate();
    } catch (error) {
      console.error('Error deleting route:', error);
      console.error('Error details:', error.response?.data);
      toast.error(error.response?.data?.message || 'Failed to delete route');
    } finally {
      setLoading(false);
    }
  };

  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    const reorderedJobs = Array.from(jobs);
    const [reorderedItem] = reorderedJobs.splice(result.source.index, 1);
    reorderedJobs.splice(result.destination.index, 0, reorderedItem);

    try {
      await axiosInstance.put(`/routes/${route._id}/reorder`, {
        jobOrder: reorderedJobs.map(job => job._id)
      });
      if (onUpdate) onUpdate();
      toast.success('Job order updated');
    } catch (error) {
      console.error('Error reordering jobs:', error);
      toast.error('Failed to update job order');
    }
  };

  const calculateRouteStatus = (jobs) => {
    if (!jobs?.length) return 'No Jobs';
    
    const completedJobs = jobs.filter(job => job.status === 'Completed').length;
    const totalJobs = jobs.length;
    
    if (completedJobs === 0) return 'Not Started';
    if (completedJobs === totalJobs) return 'Completed';
    return `In Progress (${completedJobs}/${totalJobs})`;
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Completed':
        return 'text-green-400';
      case 'In Progress':
        return 'text-yellow-400';
      case 'Not Started':
        return 'text-blue-400';
      default:
        return 'text-gray-400';
    }
  };

  if (!isOpen || !route) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-gradient-to-br from-gray-800 to-gray-900 rounded-xl p-6 max-w-4xl w-full mx-4 shadow-2xl transform transition-all border border-purple-500">
        <div className="flex justify-between items-center mb-6 border-b border-gray-700 pb-4">
          <div className="flex-1">
            {isEditing ? (
              <div className="flex items-center gap-2">
                <input
                  type="text"
                  value={routeName}
                  onChange={(e) => setRouteName(e.target.value)}
                  className="bg-gray-700 text-white px-3 py-2 rounded-lg border border-purple-500/30 focus:border-purple-500 focus:outline-none"
                  placeholder="Enter route name"
                />
                <button
                  onClick={handleSaveName}
                  disabled={loading}
                  className="bg-purple-600 hover:bg-purple-700 text-white px-4 py-2 rounded-lg transition-colors"
                >
                  Save
                </button>
                <button
                  onClick={() => {
                    setIsEditing(false);
                    setRouteName(route.name);
                  }}
                  className="bg-gray-700 hover:bg-gray-600 text-white px-4 py-2 rounded-lg transition-colors"
                >
                  Cancel
                </button>
              </div>
            ) : (
              <div>
                <div className="flex items-center gap-2">
                  <h2 className="text-2xl font-bold bg-gradient-to-r from-purple-400 to-purple-600 bg-clip-text text-transparent">
                    {route.name || `Route ${new Date(route.date).toLocaleDateString()}`}
                  </h2>
                  <button
                    onClick={() => setIsEditing(true)}
                    className="text-gray-400 hover:text-purple-400 transition-colors"
                  >
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                    </svg>
                  </button>
                </div>
                <p className="text-lg text-purple-300">
                  {new Date(route.date).toLocaleDateString('en-US', { 
                    weekday: 'long', 
                    year: 'numeric', 
                    month: 'long', 
                    day: 'numeric' 
                  })}
                </p>
              </div>
            )}
          </div>
          <div className="flex items-center gap-2">
            <button
              onClick={handleDeleteRoute}
              className="text-red-400 hover:text-red-500 transition-colors p-2 hover:bg-gray-700 rounded-lg"
              title="Delete Route"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
              </svg>
            </button>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-white transition-colors p-2 hover:bg-gray-700 rounded-lg"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>

        <div className="grid grid-cols-4 gap-6 mb-6">
          <div className="bg-gray-700 bg-opacity-50 p-4 rounded-lg border border-purple-500/30 hover:border-purple-500/50 transition-colors">
            <p className="text-purple-300 text-sm font-medium mb-1">Total Revenue</p>
            <p className="text-white text-lg font-semibold">{formatCurrency(totalRevenue)}</p>
          </div>
          <div className="bg-gray-700 bg-opacity-50 p-4 rounded-lg border border-purple-500/30 hover:border-purple-500/50 transition-colors">
            <p className="text-purple-300 text-sm font-medium mb-1">Total Jobs</p>
            <p className="text-white text-lg font-semibold">{jobs?.length || 0}</p>
          </div>
          <div className="bg-gray-700 bg-opacity-50 p-4 rounded-lg border border-purple-500/30 hover:border-purple-500/50 transition-colors">
            <p className="text-purple-300 text-sm font-medium mb-1">Assigned To</p>
            <div className="flex flex-col">
              {selectedCrew && crews.find(c => c._id === selectedCrew) ? (
                <p className="text-white text-lg font-semibold">
                  {crews.find(c => c._id === selectedCrew)?.name || 'Loading...'}
                </p>
              ) : selectedEmployee && employees.find(e => e._id === selectedEmployee) ? (
                <p className="text-white text-lg font-semibold">
                  {employees.find(e => e._id === selectedEmployee)?.name || 'Loading...'}
                </p>
              ) : (
                <p className="text-gray-400 text-lg font-semibold">Not Assigned</p>
              )}
            </div>
          </div>
          <div className="bg-gray-700 bg-opacity-50 p-4 rounded-lg border border-purple-500/30 hover:border-purple-500/50 transition-colors">
            <p className="text-purple-300 text-sm font-medium mb-1">Route Status</p>
            <p className={`text-lg font-semibold ${getStatusColor(calculateRouteStatus(jobs))}`}>
              {calculateRouteStatus(jobs)}
            </p>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-6 mb-6">
          <div className="bg-gray-700 bg-opacity-50 p-4 rounded-lg border border-purple-500/30">
            <h3 className="text-lg font-semibold text-purple-300 mb-3">Assign Crew</h3>
            <select
              value={selectedCrew}
              onChange={(e) => {
                const value = e.target.value;
                if (value) {
                  handleAssignment('crew', value);
                } else {
                  setSelectedCrew('');
                  setSelectedEmployee('');
                }
              }}
              disabled={loading}
              className="w-full bg-gray-800 border border-gray-600 rounded-lg p-2 text-white disabled:opacity-50"
            >
              <option value="">Select Crew</option>
              {crews.map(crew => (
                <option key={crew._id} value={crew._id}>{crew.name}</option>
              ))}
            </select>
          </div>
          <div className="bg-gray-700 bg-opacity-50 p-4 rounded-lg border border-purple-500/30">
            <h3 className="text-lg font-semibold text-purple-300 mb-3">Assign Employee</h3>
            <select
              value={selectedEmployee}
              onChange={(e) => {
                const value = e.target.value;
                if (value) {
                  handleAssignment('employee', value);
                } else {
                  setSelectedCrew('');
                  setSelectedEmployee('');
                }
              }}
              disabled={loading}
              className="w-full bg-gray-800 border border-gray-600 rounded-lg p-2 text-white disabled:opacity-50"
            >
              <option value="">Select Employee</option>
              {employees.map(employee => (
                <option key={employee._id} value={employee._id}>{employee.name}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="bg-gray-700 bg-opacity-50 rounded-lg border border-purple-500/30">
          <div className="p-4 border-b border-gray-600">
            <h3 className="text-lg font-semibold text-purple-300">Jobs (drag to reorder)</h3>
          </div>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="jobs">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="divide-y divide-gray-600 max-h-[400px] overflow-y-auto"
                >
                  {jobs?.map((job, index) => (
                    <Draggable key={job._id} draggableId={job._id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="p-4 hover:bg-gray-600/50 transition-colors"
                        >
                          <div className="flex flex-col gap-2">
                            <div className="flex justify-between items-start">
                              <div className="flex-1">
                                <div className="flex items-center gap-2 mb-1">
                                  <span className="text-gray-400 text-sm">#{index + 1}</span>
                                  <h4 className="text-white font-medium">{job.service}</h4>
                                  {job.isRecurring && (
                                    <span className="text-xs bg-purple-900 text-purple-100 px-2 py-1 rounded-full">
                                      {job.recurrencePattern}
                                    </span>
                                  )}
                                  <span className={`text-xs px-2 py-1 rounded-full ${
                                    job.status === 'Completed' ? 'bg-green-900 text-green-100' :
                                    job.status === 'In Progress' ? 'bg-yellow-900 text-yellow-100' :
                                    'bg-blue-900 text-blue-100'
                                  }`}>
                                    {job.status}
                                  </span>
                                </div>
                                <p className="text-gray-300">{job.customer?.name || 'No customer assigned'}</p>
                                <p className="text-gray-400 text-sm truncate max-w-md">
                                  {job.location?.street}, {job.location?.city}, {job.location?.state} {job.location?.zipCode}
                                </p>
                                {job.description && job.description.trim() && (
                                  <p className="text-gray-400 text-sm mt-2 italic line-clamp-2">
                                    {job.description}
                                  </p>
                                )}
                              </div>
                              <div className="flex flex-col items-end gap-2 min-w-[120px]">
                                <div className="text-right">
                                  <p className="text-white font-medium">{formatCurrency(job.price)}</p>
                                  <p className="text-gray-300">{Math.round(job.estimatedDuration / 60000)} mins</p>
                                </div>
                                <div className="flex gap-2">
                                  <button
                                    onClick={() => handleRemoveJob(job._id, false)}
                                    className="text-yellow-500 hover:text-yellow-400 bg-gray-800 hover:bg-gray-700 px-3 py-1 rounded text-sm transition-colors"
                                  >
                                    Remove
                                  </button>
                                  <button
                                    onClick={() => handleRemoveJob(job._id, true)}
                                    className="text-red-500 hover:text-red-400 bg-gray-800 hover:bg-gray-700 px-3 py-1 rounded text-sm transition-colors"
                                  >
                                    Remove All
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>

        <div className="mt-6 flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-500 transition-colors"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default RouteDetailsModal; 