import React, { useState, useEffect } from 'react';
import axiosInstance from '../utils/axiosInstance';
import { Link } from 'react-router-dom';
import { 
  PencilIcon, 
  TrashIcon,
  FunnelIcon,
  ArrowPathIcon,
  UserIcon,
  EnvelopeIcon,
  PhoneIcon,
  MapPinIcon
} from '@heroicons/react/24/outline';
import { PatternFormat } from 'react-number-format';
import ConfirmDialog from '../components/ConfirmDialog';

const ManageCustomers = () => {
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [editingCustomer, setEditingCustomer] = useState(null);
  const [filter, setFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [customersPerPage] = useState(10);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    message: '',
    onConfirm: null
  });

  useEffect(() => {
    fetchCustomers();
  }, []);

  useEffect(() => {
    if (filter === '') {
      setFilteredCustomers(customers);
    } else {
      setFilteredCustomers(
        customers.filter(
          (customer) =>
            customer.name?.toLowerCase().includes(filter.toLowerCase()) ||
            customer.email?.toLowerCase().includes(filter.toLowerCase()) ||
            customer.phone?.toLowerCase().includes(filter.toLowerCase()) ||
            customer.address?.street?.toLowerCase().includes(filter.toLowerCase()) ||
            customer.address?.city?.toLowerCase().includes(filter.toLowerCase()) ||
            customer.address?.state?.toLowerCase().includes(filter.toLowerCase()) ||
            customer.address?.zipCode?.toLowerCase().includes(filter.toLowerCase()) ||
            customer.notes?.toLowerCase().includes(filter.toLowerCase())
        )
      );
    }
  }, [filter, customers]);

  const fetchCustomers = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/customers');
      setCustomers(response.data);
      setFilteredCustomers(response.data);
    } catch (error) {
      console.error('Error fetching customers:', error);
      setError('Failed to fetch customers. Please try again.');
    }
    setLoading(false);
  };

  const handleDeleteCustomers = () => {
    if (selectedCustomers.length === 0) return;

    setConfirmDialog({
      isOpen: true,
      title: 'Delete Customers',
      message: `Are you sure you want to delete ${selectedCustomers.length} selected customer(s)?`,
      onConfirm: async () => {
        try {
          await Promise.all(
            selectedCustomers.map(customerId =>
              axiosInstance.delete(`/customers/${customerId}`)
            )
          );
          fetchCustomers();
          setSelectedCustomers([]);
        } catch (error) {
          console.error('Error deleting customers:', error);
          setError('Failed to delete customers. Please try again.');
        }
      }
    });
  };

  const handleEditCustomer = (customer) => {
    setEditingCustomer({ ...customer });
  };

  const handleUpdateCustomer = async () => {
    try {
      const response = await axiosInstance.put(
        `/customers/${editingCustomer._id}`,
        editingCustomer
      );
      setCustomers(
        customers.map((customer) =>
          customer._id === editingCustomer._id ? response.data : customer
        )
      );
      setEditingCustomer(null);
    } catch (error) {
      console.error('Error updating customer:', error);
      setError('Failed to update customer. Please try again.');
    }
  };

  const handleStatusChange = async (customerId, newStatus) => {
    try {
      await axiosInstance.put(
        `/customers/${customerId}/status`,
        { status: newStatus }
      );
      setCustomers(
        customers.map((customer) =>
          customer._id === customerId ? { ...customer, status: newStatus } : customer
        )
      );
    } catch (error) {
      console.error('Error updating customer status:', error);
      setError('Failed to update customer status. Please try again.');
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setEditingCustomer(prev => ({ ...prev, [name]: checked }));
    } else if (name.includes('.')) {
      const [objectKey, nestedKey] = name.split('.');
      setEditingCustomer(prev => ({
        ...prev,
        [objectKey]: {
          ...prev[objectKey],
          [nestedKey]: value
        }
      }));
    } else {
      setEditingCustomer(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSelectCustomer = (customerId) => {
    setSelectedCustomers(prev =>
      prev.includes(customerId)
        ? prev.filter(id => id !== customerId)
        : [...prev, customerId]
    );
  };

  const handleSelectAll = () => {
    const currentPageCustomers = getCurrentPageCustomers();
    const allSelected = currentPageCustomers.every(customer => 
      selectedCustomers.includes(customer._id)
    );
    
    if (allSelected) {
      setSelectedCustomers(prev => 
        prev.filter(id => !currentPageCustomers.find(c => c._id === id))
      );
    } else {
      setSelectedCustomers(prev => [
        ...prev,
        ...currentPageCustomers
          .filter(customer => !selectedCustomers.includes(customer._id))
          .map(customer => customer._id)
      ]);
    }
  };

  const getCurrentPageCustomers = () => {
    const indexOfLastCustomer = currentPage * customersPerPage;
    const indexOfFirstCustomer = indexOfLastCustomer - customersPerPage;
    return filteredCustomers.slice(indexOfFirstCustomer, indexOfLastCustomer);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const getStatusColor = (status) => {
    switch (status) {
      case 'Active':
        return 'bg-green-100 text-green-800';
      case 'Inactive':
        return 'bg-yellow-100 text-yellow-800';
      case 'Discontinued':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <div className="min-h-screen">
      <main className="py-8">
        {/* Header Section */}
        <div className="flex justify-between items-center mb-8">
          <div>
            <h1 className="text-3xl font-bold text-white">Manage Customers</h1>
            <p className="mt-1 text-gray-400">View and manage your customer database</p>
          </div>
          
          {/* Filter Controls */}
          <div className="flex items-center space-x-4">
            <div className="relative">
              <FunnelIcon className="h-5 w-5 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                type="text"
                placeholder="Filter customers..."
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                className="pl-10 pr-4 py-2 bg-gray-700 border border-gray-600 rounded-lg text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>
            <button
              onClick={fetchCustomers}
              className="p-2 text-gray-400 hover:text-white transition-colors"
            >
              <ArrowPathIcon className="h-5 w-5" />
            </button>
          </div>
        </div>

        {/* Bulk Actions */}
        {selectedCustomers.length > 0 && (
          <div className="mb-4 flex justify-end space-x-4">
            <button
              onClick={handleDeleteCustomers}
              className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors flex items-center space-x-2"
            >
              <TrashIcon className="h-5 w-5" />
              <span>Delete Selected ({selectedCustomers.length})</span>
            </button>
          </div>
        )}

        {/* Customers Table */}
        <div className="bg-gray-800 rounded-xl shadow-xl overflow-hidden border border-gray-700">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-700">
              <thead className="bg-gray-900/50">
                <tr>
                  <th className="px-6 py-3 text-left">
                    <input
                      type="checkbox"
                      checked={
                        getCurrentPageCustomers().length > 0 &&
                        getCurrentPageCustomers().every(customer => 
                          selectedCustomers.includes(customer._id)
                        )
                      }
                      onChange={handleSelectAll}
                      className="rounded border-gray-600 text-blue-600 focus:ring-blue-500"
                    />
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Customer</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Contact</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Address</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Status</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Notes</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-700">
                {getCurrentPageCustomers().map((customer) => (
                  <tr key={customer._id} className="hover:bg-gray-700/50 transition-colors">
                    <td className="px-6 py-4">
                      <input
                        type="checkbox"
                        checked={selectedCustomers.includes(customer._id)}
                        onChange={() => handleSelectCustomer(customer._id)}
                        className="rounded border-gray-600 text-blue-600 focus:ring-blue-500"
                      />
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex items-center">
                        <UserIcon className="h-5 w-5 text-gray-400 mr-3" />
                        <div>
                          <div className="font-medium text-white">{customer.name}</div>
                          <div className="text-sm text-gray-400">ID: #{customer._id.slice(-6)}</div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="space-y-1">
                        <div className="flex items-center text-sm text-gray-300">
                          <EnvelopeIcon className="h-4 w-4 mr-2" />
                          {customer.email}
                        </div>
                        <div className="flex items-center text-sm text-gray-300">
                          <PhoneIcon className="h-4 w-4 mr-2" />
                          {customer.phone}
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex items-start space-x-2">
                        <MapPinIcon className="h-5 w-5 text-gray-400 mt-0.5" />
                        <div className="text-sm text-gray-300">
                          <div>{customer.address?.street}</div>
                          <div>{customer.address?.city}, {customer.address?.state} {customer.address?.zipCode}</div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <select
                        value={customer.status}
                        onChange={(e) => handleStatusChange(customer._id, e.target.value)}
                        className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusColor(customer.status)}`}
                      >
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                        <option value="Discontinued">Discontinued</option>
                      </select>
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-300 max-w-xs truncate">
                        {customer.notes || 'No notes'}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="flex space-x-3">
                        <button
                          onClick={() => handleEditCustomer(customer)}
                          className="text-blue-400 hover:text-blue-300 transition-colors"
                        >
                          <PencilIcon className="h-5 w-5" />
                        </button>
                        <button
                          onClick={() => handleDeleteCustomers([customer._id])}
                          className="text-red-400 hover:text-red-300 transition-colors"
                        >
                          <TrashIcon className="h-5 w-5" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          <div className="px-6 py-4 bg-gray-900/50 flex items-center justify-between">
            <div className="flex-1 flex justify-between sm:hidden">
              <button
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
                className="relative inline-flex items-center px-4 py-2 text-sm font-medium rounded-md text-gray-300 bg-gray-800 hover:bg-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Previous
              </button>
              <button
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === Math.ceil(filteredCustomers.length / customersPerPage)}
                className="relative inline-flex items-center px-4 py-2 text-sm font-medium rounded-md text-gray-300 bg-gray-800 hover:bg-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Next
              </button>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-400">
                  Showing{' '}
                  <span className="font-medium">{((currentPage - 1) * customersPerPage) + 1}</span>
                  {' '}-{' '}
                  <span className="font-medium">
                    {Math.min(currentPage * customersPerPage, filteredCustomers.length)}
                  </span>
                  {' '}of{' '}
                  <span className="font-medium">{filteredCustomers.length}</span>
                  {' '}results
                </p>
              </div>
              <div>
                <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px">
                  {[...Array(Math.ceil(filteredCustomers.length / customersPerPage))].map((_, idx) => (
                    <button
                      key={idx}
                      onClick={() => paginate(idx + 1)}
                      className={`relative inline-flex items-center px-4 py-2 border border-gray-700 text-sm font-medium
                        ${currentPage === idx + 1 
                          ? 'z-10 bg-blue-600 text-white' 
                          : 'bg-gray-800 text-gray-300 hover:bg-gray-700'}`}
                    >
                      {idx + 1}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          </div>
        </div>

        {/* Edit Modal */}
        {editingCustomer && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-gray-800 rounded-xl shadow-2xl w-full max-w-2xl">
              <div className="p-6 border-b border-gray-700">
                <h3 className="text-xl font-semibold text-white">Edit Customer</h3>
              </div>
              
              <div className="p-6 space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {/* Name */}
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-300">
                      Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={editingCustomer.name}
                      onChange={handleInputChange}
                      className="w-full bg-gray-700 border border-gray-600 text-white rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                  </div>

                  {/* Email */}
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-300">
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={editingCustomer.email}
                      onChange={handleInputChange}
                      className="w-full bg-gray-700 border border-gray-600 text-white rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                  </div>

                  {/* Phone */}
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-300">
                      Phone
                    </label>
                    <PatternFormat
                      format="(###) ###-####"
                      mask="_"
                      name="phone"
                      value={editingCustomer.phone}
                      onValueChange={(values) => {
                        handleInputChange({
                          target: {
                            name: 'phone',
                            value: values.value
                          }
                        });
                      }}
                      className="w-full bg-gray-700 border border-gray-600 text-white rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                  </div>

                  {/* Address Fields */}
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-300">
                      Street
                    </label>
                    <input
                      type="text"
                      name="address.street"
                      value={editingCustomer.address?.street || ''}
                      onChange={handleInputChange}
                      className="w-full bg-gray-700 border border-gray-600 text-white rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                  </div>

                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-300">
                      City
                    </label>
                    <input
                      type="text"
                      name="address.city"
                      value={editingCustomer.address?.city || ''}
                      onChange={handleInputChange}
                      className="w-full bg-gray-700 border border-gray-600 text-white rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                  </div>

                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-300">
                      State
                    </label>
                    <input
                      type="text"
                      name="address.state"
                      value={editingCustomer.address?.state || ''}
                      onChange={handleInputChange}
                      className="w-full bg-gray-700 border border-gray-600 text-white rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                  </div>

                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-300">
                      Zip Code
                    </label>
                    <input
                      type="text"
                      name="address.zipCode"
                      value={editingCustomer.address?.zipCode || ''}
                      onChange={handleInputChange}
                      className="w-full bg-gray-700 border border-gray-600 text-white rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                  </div>

                  {/* Notes */}
                  <div className="col-span-2 space-y-2">
                    <label className="block text-sm font-medium text-gray-300">
                      Notes
                    </label>
                    <textarea
                      name="notes"
                      value={editingCustomer.notes || ''}
                      onChange={handleInputChange}
                      rows="3"
                      className="w-full bg-gray-700 border border-gray-600 text-white rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    ></textarea>
                  </div>

                  {/* Landscaping Potential */}
                  <div className="col-span-2">
                    <label className="flex items-center space-x-3">
                      <input
                        type="checkbox"
                        name="landscapingPotential"
                        checked={editingCustomer.landscapingPotential || false}
                        onChange={handleInputChange}
                        className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-600 rounded"
                      />
                      <span className="text-gray-300">Landscaping Potential</span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="px-6 py-4 bg-gray-900/50 flex justify-end space-x-4">
                <button
                  onClick={() => setEditingCustomer(null)}
                  className="px-4 py-2 text-gray-300 hover:text-white transition-colors"
                >
                  Cancel
                </button>
                <button
                  onClick={handleUpdateCustomer}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Confirm Dialog */}
        <ConfirmDialog
          isOpen={confirmDialog.isOpen}
          title={confirmDialog.title}
          message={confirmDialog.message}
          onConfirm={() => {
            if (confirmDialog.onConfirm) {
              confirmDialog.onConfirm();
            }
            setConfirmDialog({ ...confirmDialog, isOpen: false });
          }}
          onCancel={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
        />
      </main>
    </div>
  );
};

export default ManageCustomers;
