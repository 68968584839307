import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Select } from 'antd';
import '../styles/modal.css';
import { toast } from 'react-hot-toast';

const RecurringJobModal = ({ isOpen, onClose, onSelect, existingRoute, routes, job }) => {
  const [selectedRouteId, setSelectedRouteId] = useState(null);
  const [newRouteName, setNewRouteName] = useState('');

  console.log('RecurringJobModal props:', {
    isOpen,
    existingRoute: existingRoute ? {
      id: existingRoute._id,
      name: existingRoute.name,
      jobs: existingRoute.jobs?.length
    } : null,
    routes: routes ? routes.map(route => ({
      id: route._id,
      name: route.name,
      jobs: route.jobs?.length
    })) : [],
    job: job ? {
      id: job._id,
      service: job.service,
      isRecurring: job.isRecurring,
      recurrencePattern: job.recurrencePattern
    } : null
  });

  const handleSelect = (choice, routeId = selectedRouteId) => {
    console.log('Modal selection:', { choice, routeId, newRouteName });
    if ((choice === 'selectRoute' || choice === 'attachFuture' || choice === 'attachAll') && !routeId) {
      toast.error('Please select a route first');
      return;
    }
    if ((choice === 'newRoute' || choice === 'newSeries') && !newRouteName.trim()) {
      toast.error('Please enter a route name');
      return;
    }
    onSelect(choice, routeId, newRouteName.trim());
    onClose();
    setNewRouteName(''); // Reset the name input
  };

  const formatRouteName = (route) => {
    const jobCount = route.jobs?.length || 0;
    const routeName = route.name || `Route ${route._id.substring(0, 8)}`;
    const jobText = jobCount === 1 ? '1 job' : `${jobCount} jobs`;
    return `${routeName} (${jobText})`;
  };

  if (!isOpen || !job) return null;

  // For one-time jobs
  if (!job.isRecurring) {
    return (
      <Modal 
        show={isOpen} 
        onHide={onClose}
        centered
        className="modern-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Schedule {job.service}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {routes && routes.length > 0 ? (
            <>
              <p className="text-gray-300 mb-4">Select an existing route or create a new one:</p>
              <Select
                className="w-full mb-4"
                placeholder="Select a route"
                onChange={(value) => setSelectedRouteId(value)}
                style={{ width: '100%' }}
              >
                {routes.map(route => (
                  <Select.Option key={route._id} value={route._id}>
                    {formatRouteName(route)}
                  </Select.Option>
                ))}
              </Select>
              <Button
                variant="primary"
                className="w-full py-3 mb-3"
                onClick={() => handleSelect('selectRoute')}
                disabled={!selectedRouteId}
              >
                Add to Selected Route
              </Button>
              <div className="text-center">
                <p className="text-gray-400 mb-2">- or -</p>
              </div>
            </>
          ) : (
            <p className="text-gray-300 mb-4">No existing routes found for this day. Create a new route?</p>
          )}
          <div className="mb-4">
            <label className="block text-gray-300 mb-2">New Route Name:</label>
            <input
              type="text"
              className="w-full p-2 rounded bg-gray-700 text-white border border-gray-600 focus:border-blue-500 focus:outline-none"
              placeholder="Enter route name"
              value={newRouteName}
              onChange={(e) => setNewRouteName(e.target.value)}
            />
          </div>
          <Button
            variant="outline-primary"
            className="w-full py-3"
            onClick={() => handleSelect('newRoute')}
          >
            Create New Route
          </Button>
        </Modal.Body>
      </Modal>
    );
  }

  // For recurring jobs
  return (
    <Modal 
      show={isOpen} 
      onHide={onClose}
      centered
      className="modern-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Schedule {job.service}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="space-y-4">
          {routes?.length > 0 && (
            <>
              <p className="text-gray-300 mb-2">Select a route:</p>
              <Select
                className="w-full mb-4"
                placeholder="Select a route"
                onChange={(value) => setSelectedRouteId(value)}
                style={{ width: '100%' }}
              >
                {routes.map(route => (
                  <Select.Option key={route._id} value={route._id}>
                    {formatRouteName(route)}
                  </Select.Option>
                ))}
              </Select>
              <Button
                variant="info"
                className="w-full py-3 mb-3"
                onClick={() => handleSelect('attachFuture')}
                disabled={!selectedRouteId}
              >
                Add to Selected Route (from this date forward)
              </Button>
              <Button
                variant="success"
                className="w-full py-3 mb-3"
                onClick={() => handleSelect('attachAll')}
                disabled={!selectedRouteId}
              >
                Add to Selected Route (all dates)
              </Button>
              <div className="text-center">
                <p className="text-gray-400 mb-2">- or -</p>
              </div>
            </>
          )}
          
          <Button
            variant="outline-primary"
            className="w-full py-3 mb-3"
            onClick={() => handleSelect('single')}
          >
            Schedule for this day only
          </Button>
          
          <div className="mb-4">
            <label className="block text-gray-300 mb-2">New Route Name:</label>
            <input
              type="text"
              className="w-full p-2 rounded bg-gray-700 text-white border border-gray-600 focus:border-blue-500 focus:outline-none"
              placeholder="Enter route name"
              value={newRouteName}
              onChange={(e) => setNewRouteName(e.target.value)}
            />
          </div>
          <Button
            variant="primary"
            className="w-full py-3"
            onClick={() => handleSelect('newSeries')}
          >
            Create new recurring series
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RecurringJobModal; 