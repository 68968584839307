import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosInstance';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

const ConnectRefresh = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const refreshOnboarding = async () => {
      console.log('ConnectRefresh: Starting onboarding refresh');
      try {
        console.log('ConnectRefresh: Calling /connect/refresh-link');
        const response = await axiosInstance.post('/connect/refresh-link');
        console.log('ConnectRefresh: Received response:', response.data);

        if (response.data && response.data.url) {
          const redirectUrl = response.data.url;
          console.log('ConnectRefresh: Redirecting to Stripe URL:', redirectUrl);
          
          // Try opening in new window first
          const stripeWindow = window.open(redirectUrl, '_blank');
          
          if (!stripeWindow) {
            console.log('ConnectRefresh: Popup blocked, trying direct redirect');
            window.location.replace(redirectUrl);
          }
        } else {
          console.error('ConnectRefresh: No URL in response:', response.data);
          navigate('/profile?tab=payments');
        }
      } catch (error) {
        console.error('ConnectRefresh: Error refreshing onboarding:', {
          error: error.message,
          response: error.response?.data,
          status: error.response?.status
        });
        navigate('/profile?tab=payments&error=refresh_failed');
      }
    };

    refreshOnboarding();
  }, [navigate]);

  const handleBack = () => {
    navigate('/profile?tab=payments');
  };

  return (
    <div className="min-h-screen bg-gray-900">
      <div className="max-w-4xl mx-auto px-4 py-8">
        <button
          onClick={handleBack}
          className="mb-8 flex items-center text-gray-400 hover:text-white transition-colors"
        >
          <ArrowLeftIcon className="h-5 w-5 mr-2" />
          <span>Back to Profile</span>
        </button>

        <div className="text-center">
          <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-blue-500 mx-auto mb-4"></div>
          <h2 className="text-xl font-semibold text-white mb-2">Refreshing your onboarding session...</h2>
          <p className="text-gray-400">Please wait while we get things ready.</p>
          <p className="text-gray-400 mt-4">If you're not redirected automatically, click the back button to return to your profile.</p>
        </div>
      </div>
    </div>
  );
};

export default ConnectRefresh; 