import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: '/api',
  timeout: 120000,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true
});

// Helper function for friendly error messages
const getFriendlyErrorMessage = (error) => {
  if (error.code === 'ECONNABORTED') {
    return 'The request timed out. Please try again.';
  }
  if (!error.response) {
    return 'Unable to connect to the server. Please check your internet connection.';
  }
  switch (error.response.status) {
    case 400:
      return 'Invalid request. Please check your input.';
    case 401:
      return 'You need to login to access this feature.';
    case 403:
      return 'You don\'t have permission to access this resource.';
    case 404:
      return 'The requested resource was not found.';
    case 500:
      return 'An internal server error occurred. Please try again later.';
    default:
      return 'An unexpected error occurred. Please try again.';
  }
};

// Request interceptor
axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  const timestamp = new Date().toISOString();
  
  console.log(`[Auth Debug] Request interceptor:`, {
    timestamp,
    hasToken: !!token,
    url: config.url,
    method: config.method
  });

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    console.log('[Auth Debug] Token added to request headers');
  } else {
    console.log('[Auth Debug] No token found in localStorage');
  }
  
  // Ensure URL starts with a slash
  if (!config.url.startsWith('/')) {
    config.url = `/${config.url}`;
  }
  
  // Remove any double slashes in the URL
  config.url = config.url.replace(/\/+/g, '/');

  console.log(`[${timestamp}] Original Request:`, {
    method: config.method,
    url: config.url,
    data: config.data,
    headers: config.headers
  });

  console.log(`[${timestamp}] Final URL:`, `${config.baseURL}${config.url}`);
  
  return config;
}, (error) => {
  console.error('[Auth Debug] Request interceptor error:', error);
  return Promise.reject(error);
});

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    const timestamp = new Date().toISOString();
    console.log(`[Auth Debug] Response success:`, {
      timestamp,
      url: response.config.url,
      method: response.config.method,
      status: response.status
    });
    return response;
  },
  async (error) => {
    const timestamp = new Date().toISOString();
    console.log('[Auth Debug] Response error:', {
      timestamp,
      url: error.config?.url,
      method: error.config?.method,
      status: error.response?.status,
      message: error.message
    });

    const originalRequest = error.config;

    // If error is 401 and we haven't tried refreshing yet
    if (error.response?.status === 401 && !originalRequest._retry) {
      console.log('[Auth Debug] Attempting token refresh');
      originalRequest._retry = true;
      try {
        // Try to refresh the token
        const response = await axios.post('/api/auth/refresh-token', {}, { 
          withCredentials: true 
        });
        
        if (response.data.token) {
          console.log('[Auth Debug] Token refresh successful');
          localStorage.setItem('token', response.data.token);
          axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
          return axiosInstance(originalRequest);
        } else {
          console.log('[Auth Debug] Token refresh response missing token');
        }
      } catch (refreshError) {
        console.error('[Auth Debug] Token refresh failed:', {
          status: refreshError.response?.status,
          message: refreshError.message
        });
        
        // Only remove token and redirect if refresh actually failed
        if (refreshError.response?.status === 401) {
          console.log('[Auth Debug] Clearing token and redirecting to signin');
          localStorage.removeItem('token');
          window.location.href = '/signin';
        }
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
