import React, { useState, useEffect } from 'react';
import axiosInstance from '../utils/axiosInstance';
import { 
  UserPlusIcon, 
  ArrowUpOnSquareIcon, 
  InformationCircleIcon,
  XMarkIcon,
  EnvelopeIcon,
  PhoneIcon,
  MapPinIcon,
  DocumentTextIcon,
  CheckIcon,
  CreditCardIcon
} from '@heroicons/react/24/outline';
import Select from 'react-select';
import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import { PatternFormat } from 'react-number-format';

// Full list of U.S. states
const stateOptions = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
];

// Define libraries array outside component to avoid reloading warning
const libraries = ['places'];

const InfoTooltip = ({ text }) => (
  <div className="relative inline-block group ml-1 text-gray-400 cursor-pointer">
    <InformationCircleIcon className="h-5 w-5" />
    <div className="absolute left-1/2 bottom-full mb-2 -translate-x-1/2 hidden group-hover:block bg-gray-800 text-white text-sm p-4 rounded-lg shadow-lg w-80 z-10">
      <div className="relative">
        <div className="absolute h-3 w-3 bg-gray-800 transform rotate-45 left-1/2 -translate-x-1/2 -bottom-1.5"></div>
        {text}
      </div>
    </div>
  </div>
);

const Customers = () => {
  const [customer, setCustomer] = useState({
    name: '',
    email: '',
    phone: '',
    address: {
      street: '',
      city: '',
      state: '',
      zipCode: '',
      coordinates: []
    },
    notes: '',
    status: 'Active',
    yardSize: '',
    landscapingPotential: false
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [csvFile, setCsvFile] = useState(null);
  const [showCsvFormat, setShowCsvFormat] = useState(false);
  const [autocomplete, setAutocomplete] = useState(null);
  const [stripeSyncStatus, setStripeSyncStatus] = useState(null);
  const [stripeConnected, setStripeConnected] = useState(false);

  // Add useEffect to check Stripe Connect status
  useEffect(() => {
    checkStripeStatus();
  }, []);

  const checkStripeStatus = async () => {
    try {
      const response = await axiosInstance.get('/connect/status');
      setStripeConnected(response.data.isOnboarded);
    } catch (error) {
      console.error('Error checking Stripe status:', error);
      setStripeConnected(false);
    }
  };

  // Load Google Maps Places API with static libraries array
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries
  });

  const handlePlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      const addressComponents = place.address_components;
      const streetNumber = addressComponents.find(c => c.types.includes('street_number'))?.long_name || '';
      const route = addressComponents.find(c => c.types.includes('route'))?.long_name || '';
      const city = addressComponents.find(c => c.types.includes('locality') || c.types.includes('sublocality'))?.long_name || '';
      const state = addressComponents.find(c => c.types.includes('administrative_area_level_1'))?.short_name || '';
      const zipCode = addressComponents.find(c => c.types.includes('postal_code'))?.long_name || '';
      
      // Get coordinates
      const coordinates = place.geometry?.location ? [
        place.geometry.location.lng(),
        place.geometry.location.lat()
      ] : [];

      setCustomer(prev => ({
        ...prev,
        address: {
          ...prev.address,
          street: `${streetNumber} ${route}`.trim(),
          city,
          state,
          zipCode,
          coordinates
        }
      }));
    }
  };

  const onLoadAutocomplete = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target || {};
    if (type === 'checkbox') {
      setCustomer(prev => ({ ...prev, [name]: checked }));
    } else if (name && name.includes('.')) {
      const [parent, child] = name.split('.');
      setCustomer(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value
        }
      }));
    } else {
      setCustomer(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');
    setStripeSyncStatus(null);

    try {
      const formattedCustomer = {
        ...customer,
        phone: customer.phone.replace(/\D/g, '')
      };

      const response = await axiosInstance.post('/customers', formattedCustomer);
      
      // If Stripe is connected, show sync status
      if (stripeConnected) {
        setStripeSyncStatus(response.data.stripeSyncStatus);
        if (response.data.stripeSyncStatus === 'failed') {
          setError(`Customer created but Stripe sync failed: ${response.data.stripeSyncError}`);
        } else {
          setSuccess('Customer added successfully and synced with Stripe!');
        }
      } else {
        setSuccess('Customer added successfully!');
      }

      setCustomer({
        name: '',
        email: '',
        phone: '',
        address: { street: '', city: '', state: '', zipCode: '', coordinates: [] },
        notes: '',
        status: 'Active',
        yardSize: '',
        landscapingPotential: false
      });
    } catch (error) {
      console.error('Error creating customer:', error);
      setError(error.response?.data?.message || 'Failed to add customer. Please try again.');
    }
    setLoading(false);
  };

  const handleCsvUpload = async (e) => {
    e.preventDefault();
    if (!csvFile) {
      setError('Please select a CSV file to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('file', csvFile);

    setLoading(true);
    try {
      const response = await axiosInstance.post('/customers/import', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.data.syncedWithStripe !== undefined) {
        setSuccess(
          `Customers imported successfully! Total: ${response.data.total}, ` +
          `Synced with Stripe: ${response.data.syncedWithStripe}, ` +
          `Failed to sync: ${response.data.syncFailed}`
        );
      } else {
        setSuccess(`${response.data.total} customers imported successfully!`);
      }
      setCsvFile(null);
    } catch (error) {
      console.error('Error importing customers:', error);
      setError('Failed to import customers. Ensure the CSV format is correct and all required fields are present.');
    }
    setLoading(false);
  };

  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: 'rgb(55, 65, 81)',
      borderColor: 'rgb(75, 85, 99)',
      color: 'white',
      '&:hover': {
        borderColor: 'rgb(96, 165, 250)'
      }
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white',
    }),
    input: (provided) => ({
      ...provided,
      color: 'white',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: 'rgb(31, 41, 55)',
      borderColor: 'rgb(75, 85, 99)',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'rgb(37, 99, 235)' : state.isFocused ? 'rgb(55, 65, 81)' : 'transparent',
      color: 'white',
      '&:hover': {
        backgroundColor: 'rgb(55, 65, 81)',
      }
    }),
  };

  return (
    <div className="min-h-screen">
      <main className="py-8">
        {/* Header Section */}
        <div className="flex justify-between items-center mb-8">
          <div>
            <h1 className="text-3xl font-bold text-white">Create Customer</h1>
            <p className="mt-1 text-gray-400">Add a new customer to your database</p>
          </div>
        </div>

        {/* Alerts */}
        {error && (
          <div className="mb-6 bg-red-500/10 border border-red-500 text-red-500 px-4 py-3 rounded-lg flex items-center">
            <XMarkIcon className="h-5 w-5 mr-2" />
            {error}
          </div>
        )}
        {success && (
          <div className="mb-6 bg-green-500/10 border border-green-500 text-green-500 px-4 py-3 rounded-lg flex items-center">
            <CheckIcon className="h-5 w-5 mr-2" />
            {success}
          </div>
        )}

        {/* Stripe Sync Status */}
        {stripeSyncStatus && (
          <div className={`mb-6 px-4 py-3 rounded-lg flex items-center ${
            stripeSyncStatus === 'synced' 
              ? 'bg-blue-500/10 border border-blue-500 text-blue-500'
              : stripeSyncStatus === 'pending'
                ? 'bg-yellow-500/10 border border-yellow-500 text-yellow-500'
                : 'bg-red-500/10 border border-red-500 text-red-500'
          }`}>
            <div className="flex items-center">
              <div className={`h-2 w-2 rounded-full mr-2 ${
                stripeSyncStatus === 'synced'
                  ? 'bg-blue-500'
                  : stripeSyncStatus === 'pending'
                    ? 'bg-yellow-500'
                    : 'bg-red-500'
              }`} />
              <span>
                {stripeSyncStatus === 'synced'
                  ? 'Synced with Stripe'
                  : stripeSyncStatus === 'pending'
                    ? 'Syncing with Stripe...'
                    : 'Stripe sync failed'}
              </span>
            </div>
          </div>
        )}

        {/* Create Customer Form */}
        <div className="bg-gray-800 rounded-xl shadow-xl overflow-hidden border border-gray-700">
          <div className="p-6 border-b border-gray-700">
            <h2 className="text-xl font-semibold text-white flex items-center">
              <UserPlusIcon className="h-6 w-6 mr-2 text-blue-500" />
              Customer Information
              {stripeConnected && (
                <span className="ml-2 text-sm text-gray-400 flex items-center">
                  <CreditCardIcon className="h-4 w-4 mr-1" />
                  Will sync with Stripe
                </span>
              )}
            </h2>
          </div>

          <form onSubmit={handleSubmit} className="p-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Basic Information */}
              <div className="space-y-6">
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-300">
                    Name <span className="text-red-500">*</span>
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      name="name"
                      value={customer.name}
                      onChange={handleChange}
                      className="w-full bg-gray-700 border border-gray-600 text-white rounded-lg pl-10 pr-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      required
                    />
                    <UserPlusIcon className="h-5 w-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
                  </div>
                </div>

                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-300">
                    Email <span className="text-red-500">*</span>
                  </label>
                  <div className="relative">
                    <input
                      type="email"
                      name="email"
                      value={customer.email}
                      onChange={handleChange}
                      className="w-full bg-gray-700 border border-gray-600 text-white rounded-lg pl-10 pr-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      required
                    />
                    <EnvelopeIcon className="h-5 w-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
                  </div>
                </div>

                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-300">
                    Phone <span className="text-red-500">*</span>
                  </label>
                  <div className="relative">
                    <PatternFormat
                      format="(###) ###-####"
                      mask="_"
                      value={customer.phone}
                      onValueChange={(values) => {
                        handleChange({
                          target: {
                            name: 'phone',
                            value: values.value
                          }
                        });
                      }}
                      className="w-full bg-gray-700 border border-gray-600 text-white rounded-lg pl-10 pr-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      required
                    />
                    <PhoneIcon className="h-5 w-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
                  </div>
                </div>
              </div>

              {/* Address Information */}
              <div className="space-y-6">
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-300">
                    Address <span className="text-red-500">*</span>
                  </label>
                  <div className="relative">
                    {isLoaded ? (
                      <Autocomplete
                        onLoad={onLoadAutocomplete}
                        onPlaceChanged={handlePlaceChanged}
                      >
                        <input
                          type="text"
                          name="address.street"
                          value={customer.address.street}
                          onChange={handleChange}
                          className="w-full bg-gray-700 border border-gray-600 text-white rounded-lg pl-10 pr-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                          required
                        />
                      </Autocomplete>
                    ) : (
                      <input
                        type="text"
                        name="address.street"
                        value={customer.address.street}
                        onChange={handleChange}
                        className="w-full bg-gray-700 border border-gray-600 text-white rounded-lg pl-10 pr-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        required
                      />
                    )}
                    <MapPinIcon className="h-5 w-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-300">
                      City <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="address.city"
                      value={customer.address.city}
                      onChange={handleChange}
                      className="w-full bg-gray-700 border border-gray-600 text-white rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                      required
                    />
                  </div>

                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-300">
                      State <span className="text-red-500">*</span>
                    </label>
                    <Select
                      options={stateOptions}
                      value={stateOptions.find(option => option.value === customer.address.state)}
                      onChange={(selectedOption) => {
                        handleChange({
                          target: {
                            name: 'address.state',
                            value: selectedOption.value
                          }
                        });
                      }}
                      styles={customSelectStyles}
                      className="text-black"
                      required
                    />
                  </div>
                </div>

                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-300">
                    Zip Code <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="address.zipCode"
                    value={customer.address.zipCode}
                    onChange={handleChange}
                    className="w-full bg-gray-700 border border-gray-600 text-white rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    required
                  />
                </div>
              </div>
            </div>

            {/* Additional Information */}
            <div className="mt-6 space-y-6">
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-300">
                  Notes
                </label>
                <div className="relative">
                  <textarea
                    name="notes"
                    value={customer.notes}
                    onChange={handleChange}
                    rows="3"
                    className="w-full bg-gray-700 border border-gray-600 text-white rounded-lg pl-10 pr-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  ></textarea>
                  <DocumentTextIcon className="h-5 w-5 text-gray-400 absolute left-3 top-3" />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-300">
                    Yard Size
                  </label>
                  <input
                    type="text"
                    name="yardSize"
                    value={customer.yardSize}
                    onChange={handleChange}
                    className="w-full bg-gray-700 border border-gray-600 text-white rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    placeholder="e.g., Large, 1 acre, etc."
                  />
                </div>

                <div className="flex items-center space-x-3">
                  <input
                    type="checkbox"
                    name="landscapingPotential"
                    checked={customer.landscapingPotential}
                    onChange={handleChange}
                    className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-600 rounded"
                  />
                  <label className="text-gray-300">
                    Landscaping Potential
                  </label>
                </div>
              </div>
            </div>

            {/* Submit Button */}
            <div className="mt-8">
              <button
                type="submit"
                disabled={loading}
                className="w-full bg-gradient-to-r from-blue-500 to-blue-600 text-white px-6 py-3 rounded-lg font-medium hover:from-blue-600 hover:to-blue-700 transform hover:scale-105 transition duration-200 shadow-lg hover:shadow-blue-500/50 flex items-center justify-center"
              >
                {loading ? (
                  <>
                    <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-3"></div>
                    Adding Customer...
                  </>
                ) : (
                  <>
                    <UserPlusIcon className="h-5 w-5 mr-2" />
                    Add Customer
                  </>
                )}
              </button>
            </div>
          </form>
        </div>

        {/* CSV Import Section */}
        <div className="mt-8 bg-gray-800 rounded-xl shadow-xl overflow-hidden border border-gray-700">
          <div className="p-6 border-b border-gray-700">
            <div className="flex items-center">
              <ArrowUpOnSquareIcon className="h-6 w-6 text-blue-500 mr-2" />
              <h2 className="text-xl font-semibold text-white">Bulk Import</h2>
              {stripeConnected && (
                <span className="ml-2 text-sm text-gray-400 flex items-center">
                  <CreditCardIcon className="h-4 w-4 mr-1" />
                  Will sync with Stripe
                </span>
              )}
              <InfoTooltip text={
                <div className="space-y-2">
                  <p className="font-semibold">CSV File Requirements:</p>
                  <ul className="list-disc list-inside">
                    <li>File must be in CSV format (.csv)</li>
                    <li>First row must contain column headers</li>
                    <li>All required fields must be present</li>
                    <li>Phone numbers should be in (XXX) XXX-XXXX format</li>
                    {stripeConnected && (
                      <li className="text-blue-300">Customers will be automatically synced with Stripe</li>
                    )}
                  </ul>
                  <p>Click 'CSV Format Info' below for a detailed example.</p>
                </div>
              } />
            </div>
          </div>

          <div className="p-6">
            <form onSubmit={handleCsvUpload} className="space-y-4">
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-300">
                  Select CSV File
                </label>
                <input
                  type="file"
                  accept=".csv"
                  onChange={(e) => setCsvFile(e.target.files[0])}
                  className="w-full bg-gray-700 border border-gray-600 text-white rounded-lg px-4 py-2.5 focus:ring-2 focus:ring-blue-500 focus:border-transparent file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                />
              </div>

              <button
                type="submit"
                disabled={loading || !csvFile}
                className="w-full bg-gradient-to-r from-green-500 to-green-600 text-white px-6 py-3 rounded-lg font-medium hover:from-green-600 hover:to-green-700 transform hover:scale-105 transition duration-200 shadow-lg hover:shadow-green-500/50 flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {loading ? (
                  <>
                    <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-3"></div>
                    {stripeConnected ? 'Importing & Syncing...' : 'Importing...'}
                  </>
                ) : (
                  <>
                    <ArrowUpOnSquareIcon className="h-5 w-5 mr-2" />
                    Import Customers
                  </>
                )}
              </button>
            </form>
          </div>
        </div>

        {/* CSV Format Modal */}
        {showCsvFormat && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-gray-800 rounded-xl shadow-2xl w-full max-w-4xl">
              <div className="p-6 border-b border-gray-700">
                <h3 className="text-xl font-semibold text-white">CSV Format Guide</h3>
              </div>

              <div className="p-6 space-y-6">
                {/* Format Requirements */}
                <div className="space-y-4">
                  <h4 className="text-lg font-medium text-white">Requirements</h4>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="bg-gray-900/50 p-4 rounded-lg">
                      <h5 className="font-medium text-blue-400 mb-2">Required Fields</h5>
                      <ul className="space-y-2 text-gray-300">
                        <li><span className="font-mono text-blue-300">name</span> - Full name of customer</li>
                        <li><span className="font-mono text-blue-300">email</span> - Valid email address</li>
                        <li><span className="font-mono text-blue-300">phone</span> - Format: (XXX) XXX-XXXX</li>
                        <li><span className="font-mono text-blue-300">street</span> - Street address</li>
                        <li><span className="font-mono text-blue-300">city</span> - City name</li>
                        <li><span className="font-mono text-blue-300">state</span> - Two-letter state code (e.g., CA)</li>
                        <li><span className="font-mono text-blue-300">zipCode</span> - 5-digit zip code</li>
                      </ul>
                    </div>
                    <div className="bg-gray-900/50 p-4 rounded-lg">
                      <h5 className="font-medium text-blue-400 mb-2">Optional Fields</h5>
                      <ul className="space-y-2 text-gray-300">
                        <li><span className="font-mono text-blue-300">notes</span> - Additional customer notes</li>
                        <li><span className="font-mono text-blue-300">yardSize</span> - Size of the yard</li>
                        <li><span className="font-mono text-blue-300">landscapingPotential</span> - Use 'true' or 'false'</li>
                      </ul>
                    </div>
                  </div>
                </div>

                {/* Example CSV */}
                <div className="space-y-4">
                  <h4 className="text-lg font-medium text-white">Example CSV Format</h4>
                  <div className="bg-gray-900 p-4 rounded-lg space-y-4">
                    <p className="text-sm text-gray-400">Your CSV file should look like this:</p>
                    <div className="font-mono text-sm overflow-x-auto">
                      <table className="min-w-full text-gray-300">
                        <thead>
                          <tr>
                            {['name', 'email', 'phone', 'street', 'city', 'state', 'zipCode', 'notes', 'yardSize', 'landscapingPotential'].map(header => (
                              <th key={header} className="px-4 py-2 text-left border-b border-gray-700 text-blue-300">{header}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="px-4 py-2">John Doe</td>
                            <td className="px-4 py-2">john@example.com</td>
                            <td className="px-4 py-2">(123) 456-7890</td>
                            <td className="px-4 py-2">123 Main St</td>
                            <td className="px-4 py-2">Anytown</td>
                            <td className="px-4 py-2">CA</td>
                            <td className="px-4 py-2">12345</td>
                            <td className="px-4 py-2">Customer note</td>
                            <td className="px-4 py-2">Large Yard</td>
                            <td className="px-4 py-2">true</td>
                          </tr>
                          <tr>
                            <td className="px-4 py-2">Jane Smith</td>
                            <td className="px-4 py-2">jane@example.com</td>
                            <td className="px-4 py-2">(987) 654-3210</td>
                            <td className="px-4 py-2">456 Oak Ave</td>
                            <td className="px-4 py-2">Springfield</td>
                            <td className="px-4 py-2">IL</td>
                            <td className="px-4 py-2">67890</td>
                            <td className="px-4 py-2">New customer</td>
                            <td className="px-4 py-2">Small Yard</td>
                            <td className="px-4 py-2">false</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                {/* Download Template */}
                <div className="flex justify-center">
                  <button
                    onClick={() => {
                      const template = 'name,email,phone,street,city,state,zipCode,notes,yardSize,landscapingPotential\nJohn Doe,john@example.com,(123) 456-7890,123 Main St,Anytown,CA,12345,Customer note,Large Yard,true';
                      const blob = new Blob([template], { type: 'text/csv' });
                      const url = window.URL.createObjectURL(blob);
                      const a = document.createElement('a');
                      a.href = url;
                      a.download = 'customer_import_template.csv';
                      a.click();
                      window.URL.revokeObjectURL(url);
                    }}
                    className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors flex items-center"
                  >
                    <ArrowUpOnSquareIcon className="h-5 w-5 mr-2" />
                    Download CSV Template
                  </button>
                </div>
              </div>

              <div className="p-6 bg-gray-900/50 flex justify-end">
                <button
                  onClick={() => setShowCsvFormat(false)}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default Customers;
