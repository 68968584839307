import React, { useState, useEffect } from 'react';
import { Tab } from '@headlessui/react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Jobs from './Jobs';
import ManageJobs from './ManageJobs';
import Invoices from './Invoices';
import { BriefcaseIcon, ClipboardIcon, DocumentTextIcon } from '@heroicons/react/24/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const JobsHub = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const tabs = [
    { name: 'Create Job', icon: BriefcaseIcon, component: Jobs, param: 'create' },
    { name: 'Manage Jobs', icon: ClipboardIcon, component: ManageJobs, param: 'manage' },
    { name: 'Invoices', icon: DocumentTextIcon, component: Invoices, param: 'invoices' }
  ];

  useEffect(() => {
    const tab = searchParams.get('tab');
    if (tab) {
      const index = tabs.findIndex(t => t.param === tab);
      if (index !== -1) {
        setSelectedIndex(index);
      }
    }
  }, [searchParams]);

  const handleTabChange = (index) => {
    setSelectedIndex(index);
    navigate(`/jobs?tab=${tabs[index].param}`, { replace: true });
  };

  return (
    <div className="min-h-screen bg-gray-900">
      <Header />
      <main className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <div className="mb-8">
          <h1 className="text-3xl font-bold text-white">Jobs & Invoicing Hub</h1>
          <p className="mt-2 text-gray-400">Manage all your jobs and invoices in one place</p>
        </div>

        <Tab.Group selectedIndex={selectedIndex} onChange={handleTabChange}>
          <Tab.List className="flex space-x-2 rounded-xl bg-gray-800 p-1">
            {tabs.map((tab) => {
              const Icon = tab.icon;
              return (
                <Tab
                  key={tab.name}
                  className={({ selected }) =>
                    classNames(
                      'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                      'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                      selected
                        ? 'bg-blue-600 text-white shadow'
                        : 'text-gray-400 hover:bg-gray-700 hover:text-white'
                    )
                  }
                >
                  <div className="flex items-center justify-center space-x-2">
                    <Icon className="h-5 w-5" />
                    <span>{tab.name}</span>
                  </div>
                </Tab>
              );
            })}
          </Tab.List>
          <Tab.Panels className="mt-4">
            {tabs.map((tab, idx) => (
              <Tab.Panel
                key={idx}
                className={classNames(
                  'rounded-xl bg-gray-800 p-3',
                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
                )}
              >
                <tab.component />
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </main>
    </div>
  );
};

export default JobsHub; 