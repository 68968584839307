import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { LoadScript } from '@react-google-maps/api';

const GoogleMapsContext = createContext(null);

const libraries = ['places', 'geometry'];

export function GoogleMapsProvider({ children }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [loadError, setLoadError] = useState(null);
  const [googleMapsReady, setGoogleMapsReady] = useState(false);

  const handleScriptLoad = useCallback(() => {
    console.log('Google Maps script loaded');
    setIsLoaded(true);
    
    // Check if Google Maps is actually available
    if (window.google?.maps) {
      console.log('Google Maps API is ready');
      setGoogleMapsReady(true);
    } else {
      console.warn('Google Maps script loaded but API not available');
      setLoadError(new Error('Google Maps API not available after script load'));
    }
  }, []);

  const handleScriptError = useCallback((error) => {
    console.error('Error loading Google Maps script:', error);
    setLoadError(error);
  }, []);

  // Check for Google Maps availability periodically
  useEffect(() => {
    if (!isLoaded || googleMapsReady) return;

    const interval = setInterval(() => {
      if (window.google?.maps) {
        console.log('Google Maps API detected');
        setGoogleMapsReady(true);
        clearInterval(interval);
      }
    }, 100);

    // Cleanup after 10 seconds to avoid infinite checking
    const timeout = setTimeout(() => {
      clearInterval(interval);
      if (!googleMapsReady) {
        setLoadError(new Error('Google Maps API failed to initialize'));
      }
    }, 10000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [isLoaded, googleMapsReady]);

  const value = {
    isLoaded,
    loadError,
    googleMapsReady,
  };

  return (
    <GoogleMapsContext.Provider value={value}>
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        libraries={libraries}
        onLoad={handleScriptLoad}
        onError={handleScriptError}
      >
        {children}
      </LoadScript>
    </GoogleMapsContext.Provider>
  );
}

export function useGoogleMaps() {
  const context = useContext(GoogleMapsContext);
  if (context === null) {
    throw new Error('useGoogleMaps must be used within a GoogleMapsProvider');
  }
  return context;
}

export default GoogleMapsProvider; 