import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosInstance';

const ConnectReturn = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState('checking');

  useEffect(() => {
    const checkStatus = async () => {
      console.log('ConnectReturn: Starting status check');
      try {
        console.log('ConnectReturn: Calling /connect/status');
        const response = await axiosInstance.get('/connect/status');
        console.log('ConnectReturn: Received status:', response.data);
        
        if (response.data.isOnboarded) {
          console.log('ConnectReturn: Account is fully onboarded');
          setStatus('success');
          setTimeout(() => {
            console.log('ConnectReturn: Redirecting to profile with success status');
            navigate('/profile?tab=payments&status=success');
          }, 2000);
        } else {
          console.log('ConnectReturn: Account setup incomplete', {
            details: response.data
          });
          setStatus('incomplete');
          setTimeout(() => {
            console.log('ConnectReturn: Redirecting to profile with incomplete status');
            navigate('/profile?tab=payments&status=incomplete');
          }, 2000);
        }
      } catch (error) {
        console.error('ConnectReturn: Error checking status:', {
          error: error.message,
          response: error.response?.data,
          status: error.response?.status
        });
        setStatus('error');
        setTimeout(() => {
          console.log('ConnectReturn: Redirecting to profile with error status');
          navigate('/profile?tab=payments&error=status_check_failed');
        }, 2000);
      }
    };

    checkStatus();
  }, [navigate]);

  const getStatusContent = () => {
    console.log('ConnectReturn: Rendering status:', status);
    switch (status) {
      case 'success':
        return {
          title: 'Setup Complete!',
          message: 'Your Stripe account is now connected.',
          icon: '✓',
          iconClass: 'bg-green-500'
        };
      case 'incomplete':
        return {
          title: 'Setup Incomplete',
          message: 'Please complete all required steps to activate your account.',
          icon: '!',
          iconClass: 'bg-yellow-500'
        };
      case 'error':
        return {
          title: 'Something went wrong',
          message: 'We encountered an error checking your account status.',
          icon: '×',
          iconClass: 'bg-red-500'
        };
      default:
        return {
          title: 'Checking status...',
          message: 'Please wait while we verify your account setup.',
          icon: '...',
          iconClass: 'bg-blue-500'
        };
    }
  };

  const content = getStatusContent();

  return (
    <div className="min-h-screen bg-gray-900 flex items-center justify-center">
      <div className="text-center">
        <div className={`${content.iconClass} w-16 h-16 rounded-full flex items-center justify-center text-white text-2xl font-bold mx-auto mb-4`}>
          {content.icon}
        </div>
        <h2 className="text-xl font-semibold text-white mb-2">{content.title}</h2>
        <p className="text-gray-400">{content.message}</p>
        {status === 'checking' && (
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500 mx-auto mt-4"></div>
        )}
      </div>
    </div>
  );
};

export default ConnectReturn; 