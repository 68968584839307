import React, { useState, useEffect } from 'react';
import axiosInstance from '../utils/axiosInstance';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import { PlusIcon, TrashIcon, UserIcon, PhoneIcon, EnvelopeIcon, MapPinIcon, CalendarIcon, ClockIcon } from '@heroicons/react/24/outline';

const Employee = () => {
  const [employees, setEmployees] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: {
      street: '',
      city: '',
      state: '',
      zipCode: '',
      coordinates: [0, 0] // Default coordinates
    },
    hireDate: '',
    status: 'Active',
    jobsCompleted: 0,
    totalHoursWorked: 0,
    averageJobRating: 0
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    try {
      const response = await axiosInstance.get('/employees');
      setEmployees(response.data);
    } catch (error) {
      console.error('Error fetching employees:', error);
      setError('Failed to fetch employees. Please try again.');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setFormData(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    
    try {
      // Validate required fields
      if (!formData.name || !formData.email) {
        setError('Name and email are required fields.');
        return;
      }

      // Format and validate email
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email.trim())) {
        setError('Please enter a valid email address.');
        return;
      }

      // Format the data to match the backend model exactly
      const employeeData = {
        name: formData.name.trim(),
        email: formData.email.trim().toLowerCase(),
        phone: formData.phone.trim(),
        address: {
          street: formData.address.street.trim(),
          city: formData.address.city.trim(),
          state: formData.address.state.trim(),
          zipCode: formData.address.zipCode.trim(),
          coordinates: [0, 0]
        },
        status: formData.status,
        hireDate: formData.hireDate ? new Date(formData.hireDate).toISOString() : new Date().toISOString(),
        jobsCompleted: 0,
        totalHoursWorked: 0,
        averageJobRating: 0
      };

      // Debug logs
      console.log('=== Employee Creation Debug ===');
      console.log('Form Data:', formData);
      console.log('Formatted Employee Data:', employeeData);
      console.log('Validation Checks:');
      console.log('- Name present:', !!employeeData.name);
      console.log('- Email valid:', emailRegex.test(employeeData.email));
      console.log('- Date formatted:', employeeData.hireDate);
      console.log('- Address structure:', JSON.stringify(employeeData.address, null, 2));
      console.log('================================');

      // Make the API call
      try {
        const response = await axiosInstance.post('/employees', employeeData);
        console.log('=== API Response Debug ===');
        console.log('Status:', response.status);
        console.log('Response Data:', response.data);
        console.log('======================');

        setEmployees(prev => [...prev, response.data]);
        setSuccess('Employee added successfully!');
        
        // Reset form
        setFormData({
          name: '',
          email: '',
          phone: '',
          address: {
            street: '',
            city: '',
            state: '',
            zipCode: '',
            coordinates: [0, 0]
          },
          hireDate: '',
          status: 'Active',
          jobsCompleted: 0,
          totalHoursWorked: 0,
          averageJobRating: 0
        });
      } catch (apiError) {
        console.error('=== API Error Debug ===');
        console.error('Error Status:', apiError.response?.status);
        console.error('Error Response:', apiError.response?.data);
        console.error('Error Message:', apiError.message);
        console.error('Full Error Object:', apiError);
        console.error('Request Data:', apiError.config?.data);
        console.error('======================');

        // Set a more detailed error message
        const errorMessage = apiError.response?.data?.message 
          ? `Server Error: ${apiError.response.data.message}`
          : apiError.response?.data?.error
          ? `Error: ${apiError.response.data.error}`
          : 'Failed to add employee. Please check the console for more details.';

        setError(errorMessage);
      }
    } catch (error) {
      console.error('=== Unexpected Error Debug ===');
      console.error('Error Type:', error.constructor.name);
      console.error('Error Message:', error.message);
      console.error('Stack Trace:', error.stack);
      console.error('Full Error:', error);
      console.error('============================');

      setError('An unexpected error occurred. Please check the console for details.');
    }
  };

  const handleDelete = async (employeeId) => {
    if (window.confirm('Are you sure you want to delete this employee?')) {
      try {
        await axiosInstance.delete(`/employees/${employeeId}`);
        setEmployees(prev => prev.filter(emp => emp._id !== employeeId));
        setSuccess('Employee deleted successfully!');
      } catch (error) {
        console.error('Error deleting employee:', error);
        setError('Failed to delete employee. Please try again.');
      }
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-background text-text">
      <Header />
      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-6 sm:px-0">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
              Employee Management
            </h1>
          </div>

          {error && (
            <div className="mb-4 p-4 rounded-lg bg-gradient-to-r from-red-500/10 to-red-600/10 border border-red-500/20 text-red-400">
              {error}
            </div>
          )}

          {success && (
            <div className="mb-4 p-4 rounded-lg bg-gradient-to-r from-green-500/10 to-green-600/10 border border-green-500/20 text-green-400">
              {success}
            </div>
          )}

          <form onSubmit={handleSubmit} className="mb-8">
            <div className="bg-gradient-to-br from-gray-800 to-gray-900 p-8 rounded-lg shadow-lg border border-gray-700">
              <h2 className="text-2xl font-bold mb-8 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
                Add New Employee
              </h2>
              
              <div className="space-y-8">
                {/* Personal Information Section */}
                <div>
                  <h3 className="text-lg font-medium text-blue-300 mb-4">Personal Information</h3>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                      <label className="block text-sm font-medium text-blue-100 mb-2">Name</label>
                      <div className="relative rounded-lg shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <UserIcon className="h-5 w-5 text-gray-400" />
                        </div>
                        <input
                          type="text"
                          name="name"
                          value={formData.name}
                          onChange={handleInputChange}
                          className="bg-gray-900/50 border border-gray-700 text-blue-100 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-3 placeholder-gray-400"
                          placeholder="John Doe"
                          required
                        />
                      </div>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-blue-100 mb-2">Email</label>
                      <div className="relative rounded-lg shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <EnvelopeIcon className="h-5 w-5 text-gray-400" />
                        </div>
                        <input
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          className="bg-gray-900/50 border border-gray-700 text-blue-100 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-3 placeholder-gray-400"
                          placeholder="john@example.com"
                          required
                        />
                      </div>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-blue-100 mb-2">Phone</label>
                      <div className="relative rounded-lg shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <PhoneIcon className="h-5 w-5 text-gray-400" />
                        </div>
                        <input
                          type="tel"
                          name="phone"
                          value={formData.phone}
                          onChange={handleInputChange}
                          className="bg-gray-900/50 border border-gray-700 text-blue-100 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-3 placeholder-gray-400"
                          placeholder="(555) 123-4567"
                        />
                      </div>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-blue-100 mb-2">Status</label>
                      <select
                        name="status"
                        value={formData.status}
                        onChange={handleInputChange}
                        className="bg-gray-900/50 border border-gray-700 text-blue-100 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                      >
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                        <option value="On Leave">On Leave</option>
                      </select>
                    </div>
                  </div>
                </div>

                {/* Address Section */}
                <div>
                  <h3 className="text-lg font-medium text-blue-300 mb-4">Address Information</h3>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="md:col-span-2">
                      <label className="block text-sm font-medium text-blue-100 mb-2">Street Address</label>
                      <div className="relative rounded-lg shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <MapPinIcon className="h-5 w-5 text-gray-400" />
                        </div>
                        <input
                          type="text"
                          name="address.street"
                          value={formData.address.street}
                          onChange={handleInputChange}
                          className="bg-gray-900/50 border border-gray-700 text-blue-100 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-3 placeholder-gray-400"
                          placeholder="123 Main St"
                        />
                      </div>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-blue-100 mb-2">City</label>
                      <input
                        type="text"
                        name="address.city"
                        value={formData.address.city}
                        onChange={handleInputChange}
                        className="bg-gray-900/50 border border-gray-700 text-blue-100 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 placeholder-gray-400"
                        placeholder="City"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-blue-100 mb-2">State</label>
                      <input
                        type="text"
                        name="address.state"
                        value={formData.address.state}
                        onChange={handleInputChange}
                        className="bg-gray-900/50 border border-gray-700 text-blue-100 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 placeholder-gray-400"
                        placeholder="State"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-blue-100 mb-2">ZIP Code</label>
                      <input
                        type="text"
                        name="address.zipCode"
                        value={formData.address.zipCode}
                        onChange={handleInputChange}
                        className="bg-gray-900/50 border border-gray-700 text-blue-100 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 placeholder-gray-400"
                        placeholder="12345"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-blue-100 mb-2">Hire Date</label>
                      <div className="relative rounded-lg shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <CalendarIcon className="h-5 w-5 text-gray-400" />
                        </div>
                        <input
                          type="date"
                          name="hireDate"
                          value={formData.hireDate}
                          onChange={handleInputChange}
                          className="bg-gray-900/50 border border-gray-700 text-blue-100 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-3"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-8 flex justify-end">
                <button
                  type="submit"
                  className="bg-gradient-to-r from-blue-500 to-purple-500 text-white px-8 py-3 rounded-lg font-medium hover:from-blue-600 hover:to-purple-600 transform hover:scale-105 transition-all duration-200 shadow-lg hover:shadow-blue-500/50 flex items-center justify-center"
                >
                  <PlusIcon className="h-5 w-5 mr-2" />
                  Add Employee
                </button>
              </div>
            </div>
          </form>

          <h2 className="text-2xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
            Current Employees
          </h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {employees.map(employee => (
              <div key={employee._id} className="bg-gradient-to-br from-gray-800 to-gray-900 p-6 rounded-lg shadow-lg border border-gray-700 hover:border-blue-500/50 transition-all duration-200">
                <div className="flex items-center mb-4">
                  <UserIcon className="h-8 w-8 text-blue-400 mr-3" />
                  <div>
                    <div className="text-xl font-semibold text-blue-100">{employee.name}</div>
                    <div className="text-sm text-gray-400">{employee.status}</div>
                  </div>
                </div>
                
                <div className="space-y-2 mb-4">
                  <div className="flex items-center text-gray-300">
                    <EnvelopeIcon className="h-5 w-5 mr-2 text-gray-400" />
                    {employee.email}
                  </div>
                  {employee.phone && (
                    <div className="flex items-center text-gray-300">
                      <PhoneIcon className="h-5 w-5 mr-2 text-gray-400" />
                      {employee.phone}
                    </div>
                  )}
                  {employee.address?.street && (
                    <div className="flex items-center text-gray-300">
                      <MapPinIcon className="h-5 w-5 mr-2 text-gray-400" />
                      <div>
                        {employee.address.street}<br />
                        {employee.address.city}, {employee.address.state} {employee.address.zipCode}
                      </div>
                    </div>
                  )}
                  <div className="flex items-center text-gray-300">
                    <CalendarIcon className="h-5 w-5 mr-2 text-gray-400" />
                    Hired: {new Date(employee.hireDate).toLocaleDateString()}
                  </div>
                </div>

                <div className="flex justify-between items-center mt-4 pt-4 border-t border-gray-700">
                  <div className="text-sm text-gray-400">
                    Jobs: {employee.jobsCompleted || 0}
                  </div>
                  <button
                    onClick={() => handleDelete(employee._id)}
                    className="bg-gradient-to-r from-red-500 to-rose-500 text-white px-4 py-2 rounded-lg hover:from-red-600 hover:to-rose-600 transform hover:scale-105 transition-all duration-200 flex items-center"
                  >
                    <TrashIcon className="h-5 w-5 mr-2" />
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Employee;
