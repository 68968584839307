import React from 'react';
import { formatCurrency } from '../utils/formatters';

const JobDetailsModal = ({ isOpen, onClose, job, onUpdate }) => {
  if (!isOpen || !job) return null;

  // Format duration to handle NaN
  const formatDuration = (duration) => {
    const mins = parseInt(duration / 60000);
    return isNaN(mins) ? '60 mins' : `${mins} mins`;
  };

  // Format empty values
  const formatValue = (value, defaultText = 'Not specified') => {
    return value || defaultText;
  };

  // Format address from location object
  const formatAddress = (location) => {
    if (!location) return 'Not specified';
    const { street, city, state, zipCode } = location;
    if (!street && !city && !state && !zipCode) return 'Not specified';
    return `${street || ''} ${city || ''}, ${state || ''} ${zipCode || ''}`.trim();
  };

  // Format customer name
  const formatCustomerName = (customer) => {
    if (!customer) return 'Not specified';
    return customer.name || customer.firstName && customer.lastName ? 
      `${customer.firstName || ''} ${customer.lastName || ''}`.trim() : 
      'Not specified';
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-gradient-to-br from-gray-800 to-gray-900 rounded-xl p-6 max-w-2xl w-full mx-4 shadow-2xl transform transition-all border border-purple-500">
        {/* Header */}
        <div className="flex justify-between items-center mb-6 border-b border-gray-700 pb-4">
          <div>
            <h2 className="text-2xl font-bold bg-gradient-to-r from-purple-400 to-purple-600 bg-clip-text text-transparent">
              {job.service}
            </h2>
            <p className="text-lg text-purple-300">
              {formatCustomerName(job.customer)}
            </p>
          </div>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-white transition-colors p-2 hover:bg-gray-700 rounded-lg"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        {/* Job Details Grid */}
        <div className="grid grid-cols-2 gap-6 mb-6">
          <div className="bg-gray-700 bg-opacity-50 p-4 rounded-lg border border-purple-500/30 hover:border-purple-500/50 transition-colors">
            <p className="text-purple-300 text-sm font-medium mb-1">Price</p>
            <p className="text-white text-lg font-semibold">{formatCurrency(job.price || 0)}</p>
          </div>
          <div className="bg-gray-700 bg-opacity-50 p-4 rounded-lg border border-purple-500/30 hover:border-purple-500/50 transition-colors">
            <p className="text-purple-300 text-sm font-medium mb-1">Duration</p>
            <p className="text-white text-lg font-semibold">{formatDuration(job.estimatedDuration)}</p>
          </div>
          <div className="bg-gray-700 bg-opacity-50 p-4 rounded-lg border border-purple-500/30 hover:border-purple-500/50 transition-colors">
            <p className="text-purple-300 text-sm font-medium mb-1">Status</p>
            <p className="text-white text-lg font-semibold capitalize">{job.status || 'Pending'}</p>
          </div>
          <div className="bg-gray-700 bg-opacity-50 p-4 rounded-lg border border-purple-500/30 hover:border-purple-500/50 transition-colors">
            <p className="text-purple-300 text-sm font-medium mb-1">Frequency</p>
            <p className="text-white text-lg font-semibold capitalize">{job.recurrencePattern || 'One-time'}</p>
          </div>
        </div>

        {/* Location Details */}
        <div className="bg-gray-700 bg-opacity-50 p-4 rounded-lg mb-6 border border-purple-500/30 hover:border-purple-500/50 transition-colors">
          <h3 className="text-lg font-semibold text-purple-300 mb-4">Location Details</h3>
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <p className="text-gray-400 text-sm mb-1">Email</p>
              <p className="text-white">{formatValue(job.customer?.email)}</p>
            </div>
            <div>
              <p className="text-gray-400 text-sm mb-1">Phone</p>
              <p className="text-white">{formatValue(job.customer?.phone)}</p>
            </div>
          </div>
          <div>
            <p className="text-gray-400 text-sm mb-1">Service Address</p>
            <p className="text-white">{formatAddress(job.location)}</p>
          </div>
        </div>

        {/* Description */}
        <div className="bg-gray-700 bg-opacity-50 p-4 rounded-lg border border-purple-500/30 hover:border-purple-500/50 transition-colors">
          <h3 className="text-lg font-semibold text-purple-300 mb-3">Description</h3>
          <p className="text-white">
            {formatValue(job.description)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default JobDetailsModal; 