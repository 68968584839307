import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Alert from './Alert';
import Navbar from '../components/Navbar'; // Import Navbar
import { FcGoogle } from 'react-icons/fc'; // Add Google icon import

const Register = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(() => {
    const stored = sessionStorage.getItem('selectedPlan');
    return stored ? JSON.parse(stored) : null;
  });

  const { register, loginWithGoogle } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state?.plan) {
      setSelectedPlan(location.state.plan);
      sessionStorage.setItem('selectedPlan', JSON.stringify(location.state.plan));
    }
  }, [location]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const validateForm = () => {
    if (formData.password !== formData.confirmPassword) {
      setAlert({ type: 'error', message: 'Passwords do not match' });
      return false;
    }
    if (formData.password.length < 6) {
      setAlert({ type: 'error', message: 'Password must be at least 6 characters' });
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    setAlert(null);

    try {
      const userData = {
        name: formData.name,
        email: formData.email,
        password: formData.password
      };

      console.log('Submitting registration:', userData);
      const { user } = await register(userData);
      console.log('Registration successful:', user);

      // Get stored plan if it exists
      const storedPlan = sessionStorage.getItem('selectedPlan');

      if (storedPlan) {
        const plan = JSON.parse(storedPlan);
        sessionStorage.removeItem('selectedPlan');
        navigate('/pricing', {
          replace: true,
          state: {
            message: `Complete your ${plan.name} subscription`,
            autoSelectPlan: plan
          }
        });
      } else {
        // Redirect to home with success message
        navigate('/', {
          replace: true,
          state: { 
            message: 'Registration successful! You can now use our quote builder or upgrade to access the full app.',
            showQuoteBuilderPrompt: true 
          }
        });
      }

    } catch (error) {
      console.error('Registration error:', error);
      
      // Handle validation errors
      if (error.errors) {
        setAlert({
          type: 'error',
          message: 'Please correct the following errors:',
          errors: error.errors
        });
      } else {
        // Handle general errors
        setAlert({
          type: 'error',
          message: error.message || 'Registration failed. Please try again.'
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignUp = () => {
    if (selectedPlan) {
      sessionStorage.setItem('selectedPlan', JSON.stringify(selectedPlan));
    }
    loginWithGoogle();
  };

 return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-background text-text">
      <Navbar />
      <main className="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8 relative">
          <div className="absolute inset-0 blur-[100px] bg-gradient-to-r from-blue-500/30 to-purple-500/30 transform rotate-12"></div>
          <div className="relative">
            <div>
              <h2 className="mt-6 text-center text-3xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">
                Create your account
                {selectedPlan && (
                  <span className="block text-lg font-medium text-blue-400 mt-2">
                    Selected Plan: {selectedPlan.name}
                  </span>
                )}
              </h2>
              <p className="mt-2 text-center text-sm text-gray-600">
                Or{' '}
                <Link to="/signin" className="font-medium text-blue-400 hover:text-blue-300 transition-colors duration-200">
                  sign in to your account
                </Link>
              </p>
              {!selectedPlan && (
                <p className="mt-2 text-center text-sm text-gray-400">
                  Start with our free features or choose a plan later
                </p>
              )}
            </div>

            {alert && <Alert type={alert.type} message={alert.message} />}

            <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
              <div className="rounded-md shadow-sm -space-y-px">
                <div>
                  <label htmlFor="name" className="sr-only">
                    Full Name
                  </label>
                  <input
                    id="name"
                    name="name"
                    type="text"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-700 bg-gray-800 placeholder-gray-400 text-white rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm transition-colors duration-200"
                    placeholder="Full Name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label htmlFor="email" className="sr-only">
                    Email address
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-700 bg-gray-800 placeholder-gray-400 text-white focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm transition-colors duration-200"
                    placeholder="Email address"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label htmlFor="password" className="sr-only">
                    Password
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-700 bg-gray-800 placeholder-gray-400 text-white focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm transition-colors duration-200"
                    placeholder="Password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label htmlFor="confirmPassword" className="sr-only">
                    Confirm Password
                  </label>
                  <input
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-700 bg-gray-800 placeholder-gray-400 text-white rounded-b-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm transition-colors duration-200"
                    placeholder="Confirm Password"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  disabled={loading}
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 transform hover:scale-105 transition duration-200 shadow-lg hover:shadow-blue-500/50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  {loading ? 'Creating Account...' : 'Create Account'}
                </button>
              </div>
            </form>

            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-700" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-gradient-to-b from-gray-900 to-background text-gray-400">Or continue with</span>
                </div>
              </div>

              <div className="mt-6">
                <button
                  onClick={handleGoogleSignUp}
                  className="w-full inline-flex justify-center py-2 px-4 border border-gray-700 rounded-md shadow-lg text-sm font-medium text-white bg-gradient-to-br from-surface to-gray-800 hover:shadow-xl transition duration-300 transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <FcGoogle className="h-5 w-5 mr-2" />
                  Sign up with Google
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Register;
