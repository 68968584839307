// src/components/Header.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';

const Header = () => {
  const { user, logout } = useAuth();
  const [activeDropdown, setActiveDropdown] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user?.subscriptionActive || !user?.subscriptionTier) {
      navigate('/pricing', { 
        state: { message: 'Please select a subscription plan to access the application.' }
      });
    }
  }, [user, navigate]);

  if (!user?.subscriptionActive || !user?.subscriptionTier) {
    return null;
  }

  const toggleDropdown = (dropdownName) => {
    if (activeDropdown === dropdownName) {
      setActiveDropdown(null);
    } else {
      setActiveDropdown(dropdownName);
    }
  };

  return (
    <header className="bg-gray-800 shadow-lg">
      <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="flex items-center space-x-2">
              <img
                className="h-8 w-auto"
                src="/logo.png"
                alt="AutoLawn"
              />
              <span className="text-xl font-bold bg-gradient-to-r from-blue-400 to-purple-500 bg-clip-text text-transparent">
                AutoLawn
              </span>
            </Link>
          </div>

          <div className="flex items-center space-x-4">
            <Link to="/dashboard" className="text-blue-100 px-3 py-2 text-sm font-medium hover:text-white transition-colors">
              Dashboard
            </Link>

            {/* Jobs & Invoicing Dropdown */}
            <div className="relative">
              <button
                onClick={() => toggleDropdown('jobs')}
                className="text-blue-100 px-3 py-2 text-sm font-medium focus:outline-none flex items-center space-x-1 hover:text-white transition-colors"
              >
                <span>Jobs & Invoicing</span>
                {activeDropdown === 'jobs' ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
              </button>
              {activeDropdown === 'jobs' && (
                <div className="absolute mt-2 w-48 bg-gray-800 rounded-md shadow-lg z-10 ring-1 ring-black ring-opacity-5">
                  <Link to="/jobs" className="block px-4 py-2 text-sm text-blue-100 hover:bg-gray-700">Create Job</Link>
                  <Link to="/jobs?tab=manage" className="block px-4 py-2 text-sm text-blue-100 hover:bg-gray-700">Manage Jobs</Link>
                  <Link to="/jobs?tab=invoices" className="block px-4 py-2 text-sm text-blue-100 hover:bg-gray-700">Invoices</Link>
                </div>
              )}
            </div>

            {/* Customers Dropdown */}
            <div className="relative">
              <button
                onClick={() => toggleDropdown('customers')}
                className="text-blue-100 px-3 py-2 text-sm font-medium focus:outline-none flex items-center space-x-1 hover:text-white transition-colors"
              >
                <span>Customers</span>
                {activeDropdown === 'customers' ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
              </button>
              {activeDropdown === 'customers' && (
                <div className="absolute mt-2 w-48 bg-gray-800 rounded-md shadow-lg z-10 ring-1 ring-black ring-opacity-5">
                  <Link to="/customers?tab=create" className="block px-4 py-2 text-sm text-blue-100 hover:bg-gray-700">Create Customer</Link>
                  <Link to="/customers?tab=manage" className="block px-4 py-2 text-sm text-blue-100 hover:bg-gray-700">Manage Customers</Link>
                  <Link to="/customers?tab=map" className="block px-4 py-2 text-sm text-blue-100 hover:bg-gray-700">Customer Map</Link>
                </div>
              )}
            </div>

            {/* Routes Dropdown */}
            <div className="relative">
              <button
                onClick={() => toggleDropdown('routes')}
                className="text-blue-100 px-3 py-2 text-sm font-medium focus:outline-none flex items-center space-x-1 hover:text-white transition-colors"
              >
                <span>Routes</span>
                {activeDropdown === 'routes' ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
              </button>
              {activeDropdown === 'routes' && (
                <div className="absolute mt-2 w-48 bg-gray-800 rounded-md shadow-lg z-10 ring-1 ring-black ring-opacity-5">
                  <Link to="/route-map" className="block px-4 py-2 text-sm text-blue-100 hover:bg-gray-700">Route Map</Link>
                  <Link to="/calendar" className="block px-4 py-2 text-sm text-blue-100 hover:bg-gray-700">Calendar View</Link>
                </div>
              )}
            </div>

            {/* Employees Dropdown */}
            <div className="relative">
              <button
                onClick={() => toggleDropdown('employees')}
                className="text-blue-100 px-3 py-2 text-sm font-medium focus:outline-none flex items-center space-x-1 hover:text-white transition-colors"
              >
                <span>Employees</span>
                {activeDropdown === 'employees' ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
              </button>
              {activeDropdown === 'employees' && (
                <div className="absolute mt-2 w-48 bg-gray-800 rounded-md shadow-lg z-10 ring-1 ring-black ring-opacity-5">
                  <Link to="/add-employee" className="block px-4 py-2 text-sm text-blue-100 hover:bg-gray-700">Create Employee</Link>
                  <Link to="/manage-employees" className="block px-4 py-2 text-sm text-blue-100 hover:bg-gray-700">Manage Employees</Link>
                </div>
              )}
            </div>

            {/* Tools Dropdown */}
            <div className="relative">
              <button
                onClick={() => toggleDropdown('tools')}
                className="text-blue-100 px-3 py-2 text-sm font-medium focus:outline-none flex items-center space-x-1 hover:text-white transition-colors"
              >
                <span>Tools</span>
                {activeDropdown === 'tools' ? <ChevronUpIcon className="h-4 w-4" /> : <ChevronDownIcon className="h-4 w-4" />}
              </button>
              {activeDropdown === 'tools' && (
                <div className="absolute mt-2 w-48 bg-gray-800 rounded-md shadow-lg z-10 ring-1 ring-black ring-opacity-5">
                  <Link to="/quote-builder" className="block px-4 py-2 text-sm text-blue-100 hover:bg-gray-700">Quote Builder</Link>
                  <Link to="/material-calculator" className="block px-4 py-2 text-sm text-blue-100 hover:bg-gray-700">Material Calculator</Link>
                  <Link to="/generate-report" className="block px-4 py-2 text-sm text-blue-100 hover:bg-gray-700">Generate Report</Link>
                </div>
              )}
            </div>

            {/* Profile */}
            <Link to="/profile" className="text-blue-100 px-3 py-2 text-sm font-medium hover:text-white transition-colors">
              Profile
            </Link>

            {/* Logout Button */}
            <button
              onClick={logout}
              className="ml-4 bg-gradient-to-r from-blue-500 to-purple-500 text-white px-4 py-2 rounded-md text-sm font-medium hover:from-blue-600 hover:to-purple-600 transform hover:scale-105 transition-all duration-200 shadow-lg hover:shadow-blue-500/50"
            >
              Logout
            </button>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
