/* global google */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import axiosInstance from '../utils/axiosInstance';
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import { ExclamationTriangleIcon, BugAntIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useGoogleMaps } from '../contexts/GoogleMapsContext';

// Define marker URLs as constants using HTTPS
const MARKER_URLS = {
  ACTIVE: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png',
  INACTIVE: 'https://maps.google.com/mapfiles/ms/icons/yellow-dot.png',
  DISCONTINUED: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
  DEFAULT: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png'
};

const libraries = ['places', 'geometry'];

const ErrorMessage = ({ message, onRetry }) => (
  <div className="flex flex-col items-center justify-center p-8 bg-gray-800 rounded-lg shadow-lg">
    <ExclamationTriangleIcon className="h-12 w-12 text-yellow-500 mb-4" />
    <h3 className="text-xl font-semibold text-white mb-2">Map Loading Error</h3>
    <p className="text-gray-400 text-center mb-4">{message}</p>
    {onRetry && (
      <button
        onClick={onRetry}
        className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
      >
        Try Again
      </button>
    )}
  </div>
);

const DebugPanel = ({ state, onClose }) => (
  <div className="fixed bottom-4 right-4 w-96 bg-gray-800/90 backdrop-blur rounded-lg shadow-lg p-4 text-white text-sm z-50 border border-gray-700">
    <div className="flex justify-between items-center mb-2">
      <h3 className="font-semibold flex items-center">
        <BugAntIcon className="h-5 w-5 mr-2 text-yellow-500" />
        Debug Info
      </h3>
      <button onClick={onClose} className="text-gray-400 hover:text-white">
        <XMarkIcon className="h-5 w-5" />
      </button>
    </div>
    <div className="space-y-2 max-h-96 overflow-y-auto">
      {Object.entries(state).map(([key, value]) => (
        <div key={key} className="flex justify-between border-b border-gray-700 pb-1">
          <span className="text-gray-400">{key}:</span>
          <span className={`text-gray-200 ${value === false ? 'text-red-400' : value === true ? 'text-green-400' : ''}`}>
            {typeof value === 'boolean' 
              ? value.toString() 
              : value === null
                ? 'null'
                : typeof value === 'object' 
                  ? JSON.stringify(value) 
                  : value}
          </span>
        </div>
      ))}
    </div>
  </div>
);

const CustomerMap = () => {
  const { isLoaded, loadError, googleMapsReady } = useGoogleMaps();
  const [customers, setCustomers] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [mapCenter, setMapCenter] = useState({ lat: 37.7749, lng: -122.4194 });
  const [mapZoom, setMapZoom] = useState(12);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const mapRef = useRef(null);
  const geocoderRef = useRef(null);
  const isMounted = useRef(true);
  const [showDebug, setShowDebug] = useState(false);
  const [debugState, setDebugState] = useState({
    scriptLoaded: false,
    mapLoaded: false,
    customersLoaded: false,
    markersCount: 0,
    lastError: null,
    geocodingStatus: 'idle',
    mapCenter: null,
    selectedMarkerId: null,
    googleMapsStatus: 'initializing',
    lastEvent: null,
    customerCount: 0
  });

  // Update debug state when Google Maps status changes
  useEffect(() => {
    updateDebugState({
      scriptLoaded: isLoaded,
      googleMapsStatus: loadError ? 'error' : googleMapsReady ? 'ready' : 'loading',
      lastError: loadError?.message || null
    });
  }, [isLoaded, loadError, googleMapsReady]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const updateDebugState = useCallback((updates) => {
    console.log('Updating debug state:', updates);
    setDebugState(prev => ({
      ...prev,
      ...updates,
      lastEvent: `${new Date().toISOString()} - ${Object.keys(updates).join(', ')}`,
    }));
  }, []);

  // Add debug keyboard shortcut
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.ctrlKey && e.key === 'd') {
        setShowDebug(prev => !prev);
      }
    };
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, []);

  const geocodeCustomers = useCallback(async (customerList) => {
    if (!googleMapsReady || !isMounted.current) return;
    
    updateDebugState({
      geocodingStatus: 'started',
      customerCount: customerList.length,
    });
    
    console.log('Geocoding customer addresses...');
    const markersData = [];

    try {
      if (!geocoderRef.current) {
        geocoderRef.current = new window.google.maps.Geocoder();
      }

      for (const customer of customerList) {
        if (!isMounted.current) break;

        const address = customer.address;
        if (address) {
          const fullAddress = `${address.street}, ${address.city}, ${address.state} ${address.zipCode}`;
          console.log('Geocoding address:', fullAddress);
          updateDebugState({
            geocodingStatus: `geocoding: ${customer.name}`,
          });

          try {
            const results = await geocodeAddress(fullAddress, geocoderRef.current);
            if (results && results.length > 0 && isMounted.current) {
              const location = results[0].geometry.location;
              console.log('Geocoding successful for customer:', customer.name);
              markersData.push({
                position: { lat: location.lat(), lng: location.lng() },
                customer,
              });
              updateDebugState({
                markersCount: markersData.length,
              });
            }
          } catch (err) {
            console.error('Geocoding error for address:', fullAddress, err);
            updateDebugState({
              lastError: `Geocoding failed for ${customer.name}: ${err.message}`,
            });
          }
        }
      }

      if (isMounted.current) {
        setMarkers(markersData);
        if (markersData.length > 0) {
          const newCenter = markersData[0].position;
          setMapCenter(newCenter);
          setMapZoom(markersData.length === 1 ? 14 : 10);
          updateDebugState({
            geocodingStatus: 'complete',
            mapCenter: newCenter,
          });
        }
      }
    } catch (error) {
      console.error('Error in geocoding:', error);
      updateDebugState({
        geocodingStatus: 'error',
        lastError: error.message,
      });
      if (isMounted.current) {
        setError('Failed to load customer locations. Please try again.');
      }
    }
  }, [googleMapsReady, updateDebugState]);

  // Fetch customers when component mounts
  useEffect(() => {
    const fetchCustomers = async () => {
      if (!isMounted.current) return;

      try {
        const response = await axiosInstance.get('/customers');
        console.log('Fetched customers:', response.data);
        if (isMounted.current) {
          setCustomers(response.data);
          updateDebugState({
            customersLoaded: true,
            customerCount: response.data.length,
            lastEvent: 'Customers fetched'
          });
          if (googleMapsReady) {
            geocodeCustomers(response.data);
          }
        }
      } catch (err) {
        console.error('Error fetching customers:', err);
        if (isMounted.current) {
          setError('Failed to fetch customers. Please try again.');
          updateDebugState({
            lastError: err.message,
            lastEvent: 'Customer fetch error'
          });
        }
      } finally {
        if (isMounted.current) {
          setLoading(false);
        }
      }
    };

    fetchCustomers();
  }, [geocodeCustomers, googleMapsReady, updateDebugState]);

  const geocodeAddress = (address, geocoder) => {
    return new Promise((resolve, reject) => {
      if (!isMounted.current) {
        reject('Component unmounted');
        return;
      }

      geocoder.geocode({ address }, (results, status) => {
        if (status === 'OK') {
          resolve(results);
        } else {
          reject(`Geocode was not successful: ${status}`);
        }
      });
    });
  };

  const getMarkerColor = (customer) => {
    switch (customer.status) {
      case 'Active':
        return MARKER_URLS.ACTIVE;
      case 'Inactive':
        return MARKER_URLS.INACTIVE;
      case 'Discontinued':
        return MARKER_URLS.DISCONTINUED;
      default:
        return MARKER_URLS.DEFAULT;
    }
  };

  const handleMapLoad = useCallback((map) => {
    console.log('Map load callback triggered');
    if (map) {
      console.log('Map loaded successfully');
      mapRef.current = map;
      
      // Initialize geocoder after confirmed map load
      if (!geocoderRef.current && window.google?.maps?.Geocoder) {
        geocoderRef.current = new window.google.maps.Geocoder();
        console.log('Geocoder initialized');
      }

      updateDebugState({
        mapLoaded: true,
        googleMapsStatus: 'ready',
        lastEvent: 'Map loaded successfully'
      });

      // If we have customers but haven't geocoded them yet, do it now
      if (customers.length > 0 && markers.length === 0) {
        geocodeCustomers(customers);
      }
    }
  }, [customers, markers.length, geocodeCustomers, updateDebugState]);

  // Add debug info to marker click
  const handleMarkerClick = useCallback((marker) => {
    setSelectedMarker(marker);
    updateDebugState({
      selectedMarkerId: marker.customer._id,
      lastEvent: 'marker_clicked',
    });
  }, [updateDebugState]);

  if (loadError) {
    return (
      <div className="h-full flex items-center justify-center">
        <ErrorMessage message={loadError.message} />
      </div>
    );
  }

  return (
    <div className="h-full relative">
      {/* Debug Toggle Button */}
      <button
        onClick={() => setShowDebug(prev => !prev)}
        className="absolute top-4 right-4 z-50 bg-gray-800/90 backdrop-blur text-white px-3 py-1 rounded-lg flex items-center space-x-2 opacity-50 hover:opacity-100 transition-opacity"
      >
        <BugAntIcon className="h-5 w-5" />
        <span className="text-sm">Debug</span>
      </button>

      {showDebug && (
        <DebugPanel
          state={debugState}
          onClose={() => setShowDebug(false)}
        />
      )}

      <div className="mb-4">
        <div className="flex space-x-4 mb-2">
          <div className="flex items-center">
            <img src={MARKER_URLS.ACTIVE} alt="Active" className="h-6 w-6 mr-2" />
            <span className="text-gray-200">Active</span>
          </div>
          <div className="flex items-center">
            <img src={MARKER_URLS.INACTIVE} alt="Inactive" className="h-6 w-6 mr-2" />
            <span className="text-gray-200">Inactive</span>
          </div>
          <div className="flex items-center">
            <img src={MARKER_URLS.DISCONTINUED} alt="Discontinued" className="h-6 w-6 mr-2" />
            <span className="text-gray-200">Discontinued</span>
          </div>
        </div>
      </div>

      {googleMapsReady ? (
        <GoogleMap
          mapContainerStyle={{ height: '600px', width: '100%' }}
          center={mapCenter}
          zoom={mapZoom}
          onLoad={handleMapLoad}
          onUnmount={() => {
            mapRef.current = null;
            geocoderRef.current = null;
          }}
          options={{
            styles: [
              { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
              { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
              { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
              {
                featureType: "administrative.locality",
                elementType: "labels.text.fill",
                stylers: [{ color: "#d59563" }],
              },
              {
                featureType: "poi",
                elementType: "labels.text.fill",
                stylers: [{ color: "#d59563" }],
              },
              {
                featureType: "poi.park",
                elementType: "geometry",
                stylers: [{ color: "#263c3f" }],
              },
              {
                featureType: "poi.park",
                elementType: "labels.text.fill",
                stylers: [{ color: "#6b9a76" }],
              },
              {
                featureType: "road",
                elementType: "geometry",
                stylers: [{ color: "#38414e" }],
              },
              {
                featureType: "road",
                elementType: "geometry.stroke",
                stylers: [{ color: "#212a37" }],
              },
              {
                featureType: "road",
                elementType: "labels.text.fill",
                stylers: [{ color: "#9ca5b3" }],
              },
              {
                featureType: "water",
                elementType: "geometry",
                stylers: [{ color: "#17263c" }],
              },
              {
                featureType: "water",
                elementType: "labels.text.fill",
                stylers: [{ color: "#515c6d" }],
              },
              {
                featureType: "water",
                elementType: "labels.text.stroke",
                stylers: [{ color: "#17263c" }],
              },
            ]
          }}
        >
          {markers.map((marker, idx) => (
            <Marker
              key={idx}
              position={marker.position}
              onClick={() => handleMarkerClick(marker)}
              icon={getMarkerColor(marker.customer)}
            />
          ))}

          {selectedMarker && (
            <InfoWindow
              position={selectedMarker.position}
              onCloseClick={() => setSelectedMarker(null)}
            >
              <div className="bg-white p-2 rounded shadow">
                <h3 className="font-bold text-lg">{selectedMarker.customer.name}</h3>
                <p className="text-sm">{selectedMarker.customer.email}</p>
                <p className="text-sm">{selectedMarker.customer.phone}</p>
                <p className="text-sm">
                  {selectedMarker.customer.address.street},<br />
                  {selectedMarker.customer.address.city}, {selectedMarker.customer.address.state} {selectedMarker.customer.address.zipCode}
                </p>
                {selectedMarker.customer.notes && (
                  <p className="text-sm mt-2 italic">{selectedMarker.customer.notes}</p>
                )}
                <p className="text-sm mt-2">
                  Status: <span className="font-semibold">{selectedMarker.customer.status}</span>
                </p>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      ) : (
        <div className="flex justify-center items-center h-64">
          <div className="text-center">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-primary mb-4"></div>
            <p className="text-gray-400">Waiting for Google Maps...</p>
            <p className="text-gray-500 text-sm mt-2">
              Status: {debugState.googleMapsStatus}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerMap; 